import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  message,
  Image,
  InputNumber,
  Typography,
  Descriptions,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import Nav from "../../components/layouts/Nav";
import { useMediaQuery } from "react-responsive";
import "./cart.css";
import Checkout from "../checkout/Checkout";
import { UserContext } from "../../context";
import Link from "antd/es/typography/Link";

const { Text } = Typography;

const CartPage = () => {
  const [state, setState] = useContext(UserContext);
  const [cart, setCart] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderTotal, setOrderTotal] = useState(0);
  const [discounts, setDiscounts] = useState([]);
  const [deliveryFees, setDeliveryFees] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [deliveryFee, setDeliveryFee] = useState(0);

  useEffect(() => {
    const fetchCartData = async () => {
      const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
      console.log("Cart Items from Local Storage:", cartItems);

      try {
        const { data: discountData } = await axios.get("/discounts");
        console.log("Fetched Discounts from Backend:", discountData);
        setDiscounts(discountData);

        const updatedCartItems = cartItems.map((item) => ({
          ...item,
          quantity: item.quantity || 1,
          ...getProductDiscount(item, discountData),
        }));

        console.log("Updated Cart Items with Discounts:", updatedCartItems);
        setCart(updatedCartItems);
      } catch (error) {
        console.error("Error fetching cart data or discounts:", error);
        message.error("Failed to fetch cart data or discounts.");
      }
    };

    fetchCartData();
  }, []);

  useEffect(() => {
    setOrderTotal(getTotalOrderCost());
  }, [cart]);

  useEffect(() => {
    // Fetch delivery fees on component mount
    axios.get("/delivery-fees").then((response) => {
      setDeliveryFees(response.data);
    });
  }, []);

  const getProductDiscount = (product, discounts) => {
    if (!product || !product._id) {
      console.error("Product is missing _id:", product);
      return {
        discountCode: "N/A",
        discountPercentage: 0,
      };
    }

    const discount = discounts.find(
      (d) =>
        d.product._id === product._id && new Date() < new Date(d.expiryDate)
    );

    console.log(`Product ID: ${product._id}, Found Discount:`, discount);
    return {
      discountCode: discount ? discount.discountCode : "N/A",
      discountPercentage: discount ? discount.discountPercentage : 0,
    };
  };

  const getDiscount = (item) => {
    if (item.discountPercentage) {
      return item.price * (item.discountPercentage / 100) * item.quantity;
    }
    return 0;
  };

  const getTotalWithDiscount = () => {
    return cart.reduce(
      (total, item) => total + (item.price * item.quantity - getDiscount(item)),
      0
    );
  };

  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const getTotalOrderCost = () => {
    const totalBeforeDiscount = cart.reduce(
      (total, item) => total + item.quantity * item.price,
      0
    );
    const totalAfterDiscount = cart.reduce(
      (total, item) =>
        total +
        item.quantity *
          (item.price - item.price * (item.discountPercentage / 100)),
      0
    );
    return totalAfterDiscount + deliveryFee;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRemoveFromCart = (itemToRemove) => {
    const updatedCart = cart.filter((item) => item._id !== itemToRemove._id);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    const event = new Event("updateCartCount");
    window.dispatchEvent(event);
  };

  const handleQuantityChange = (value, item) => {
    const updatedCart = cart.map((cartItem) =>
      cartItem._id === item._id ? { ...cartItem, quantity: value } : cartItem
    );
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const handlePlaceOrder = (orderDetails) => {
    // Logic to place order
    console.log("Order details:", orderDetails);
    handleCancel();
  };

  const columns = [
    {
      title: "Product Image",
      dataIndex: "productImage",
      key: "productImage",
      render: (text, record) => (
        <Image
          src={record.productImage?.url}
          alt={record.productName}
          width={100}
        />
      ),
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => `₦${text.toFixed(2)}`,
    },
    {
      title: "Discount Code",
      dataIndex: "discountCode",
      key: "discountCode",
      render: (text) => text || "N/A",
    },
    {
      title: "Discount Percentage",
      dataIndex: "discountPercentage",
      key: "discountPercentage",
      render: (text) => (text > 0 ? `${text}%` : "N/A"),
    },
    {
      title: "Price After Discount",
      key: "priceAfterDiscount",
      render: (text, record) => {
        const discountAmount = getDiscount(record);
        const priceAfterDiscount =
          (record.price * record.quantity - discountAmount) / record.quantity;
        return `₦${priceAfterDiscount.toFixed(2)}`;
      },
    },
    {
      title: "Number of Products",
      key: "quantity",
      render: (text, record) => (
        <InputNumber
          min={1}
          value={record.quantity}
          onChange={(value) => handleQuantityChange(value, record)}
          style={{ width: "100%" }} // Optional: Ensures full width for better UI
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={() => handleRemoveFromCart(record)}
        />
      ),
    },
  ];

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <>
      <Nav />
      <div className="container mt-5">
        <Row gutter={16}>
          <Col
            xs={24}
            md={16}
            className={`order-summary ${isTabletOrMobile ? "mt-4" : ""}`}
          >
            <Card
              title="Your Cart"
              bordered={true}
              className="cart-border-custom shadow-lg"
            >
              <Table
                className="table-responsive"
                dataSource={cart}
                columns={columns}
                pagination={false}
                rowKey="_id"
              />
            </Card>
          </Col>
          <Col
            xs={24}
            md={8}
            className={`order-summary ${isTabletOrMobile ? "mt-4" : ""}`}
          >
            <Card
              title="Order Summary"
              bordered={false}
              className="shadow-lg border-custom"
            >
              <Descriptions
                column={1}
                layout="horizontal"
                className="text-center"
                style={{ textAlign: "center", justifyContent: "center" }}
              >
                <Descriptions.Item
                  label={
                    <span style={{ color: "#000000", fontWeight: 200 }}>
                      Total Products:
                    </span>
                  }
                  contentStyle={{
                    color: "#24A7A4",
                    fontWeight: "bolder",
                    justifyContent: "center",
                  }}
                >
                  {cart.reduce((total, item) => total + item.quantity, 0)}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <span style={{ color: "#000000", fontWeight: 200 }}>
                      Total Price Before Discount:
                    </span>
                  }
                  contentStyle={{
                    color: "#24A7A4",
                    fontWeight: "bolder",
                    justifyContent: "center",
                  }}
                >
                  ₦{getTotalPrice().toFixed(2)}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span style={{ color: "#000000", fontWeight: 200 }}>
                      Total Price After Discount:
                    </span>
                  }
                  contentStyle={{
                    color: "#24A7A4",
                    fontWeight: "bolder",
                    justifyContent: "center",
                  }}
                >
                  ₦{getTotalWithDiscount().toFixed(2)}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <span style={{ color: "#000000", fontWeight: 200 }}>
                      Total Order Cost:
                    </span>
                  }
                  contentStyle={{
                    color: "#24A7A4",
                    fontWeight: "bolder",
                    justifyContent: "center",
                  }}
                >
                  ₦{orderTotal.toFixed(2)}
                </Descriptions.Item>
              </Descriptions>
              <Row>
                {state && state.token ? (
                  <Button
                    type="primary"
                    size={isTabletOrMobile ? "large" : "default"}
                    onClick={showModal}
                    className="mt-5 mb-5 checkout-btn text-center"
                  >
                    Proceed to Checkout
                  </Button>
                ) : (
                  <Button
                    type="link"
                    href="/login"
                    size={isTabletOrMobile ? "large" : "default"}
                    className="mt-5 mb-5 checkout-btn text-center"
                  >
                    Login
                  </Button>
                )}
              </Row>
              <Modal
                title="Checkout"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
              >
                <Checkout
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  cartItems={cart}
                  onPlaceOrder={handlePlaceOrder}
                  deliveryFees={deliveryFees}
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  setDeliveryFee={setDeliveryFee}
                />
              </Modal>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CartPage;
