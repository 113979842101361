import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Sidebar from "../../components/layouts/Sidebar";
import { UserContext } from "../../context";
import ErrorAlert from "../../components/alerts/ErrorAlert";
import SuccessAlert from "../../components/alerts/SuccessAlert";

const Settings = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(
    () => localStorage.getItem("password") || ""
  );
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [state, setState] = useContext(UserContext);
  const [errorAlert, setErrorAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEditIconClick = () => {
    document.getElementById("avatarInput").click();
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "avatar") {
      const file = e.target.files[0];
      setAvatar(file);
      const previewUrl = URL.createObjectURL(file);
      setAvatarPreview(previewUrl);
      console.log("Avatar Preview URL:", previewUrl);
    } else if (id === "password") {
      setPassword(value);
      localStorage.setItem("password", value); // Save password to local storage
    } else {
      // Handle other input changes (name, email) here
    }
  };
  

  const handleUpdateProfile = async () => {
    try {
      const { data } = await axios.put("/update-profile", {
        name,
        email,
        password, // Include the new password
      });
  
      if (data.error) {
        setErrorAlert(<ErrorAlert message={data.error} />);
      } else {
        setSuccessAlert(
          <SuccessAlert
            message={`Hey ${data.name}. Your profile has been updated successfully!`}
          />
        );
        setState({ ...state, user: data });
  
        // Clear the locally stored password after successful update
        localStorage.removeItem("password");
      }
    } catch (err) {
      console.error(err);
      setErrorAlert(<ErrorAlert message="Something went wrong. Try again" />);
    }
  };
  

// Function to update user avatar
const handleUpdateAvatar = async () => {
  try {
    const { data } = await axios.put("/update-avatar", {
      avatar: avatarPreview, // Assuming avatarPreview contains the avatar URL
    });

    if (data.error) {
      setErrorAlert(<ErrorAlert message={data.error} />);
    } else {
      setSuccessAlert(
        <SuccessAlert
          message={`Your avatar has been updated successfully!`}
        />
      );

      // Update user context with the new user data
      setState({ ...state, user: data });

      // Update the avatar preview with the newly uploaded image URL
      setAvatarPreview(data.avatar.url);
    }
  } catch (err) {
    console.error(err);
    setErrorAlert(<ErrorAlert message="Something went wrong. Try again" />);
  }
};



  const handleImage = async (file) => {
    setAvatar(file);
    const previewUrl = URL.createObjectURL(file);
    setAvatarPreview(previewUrl);
    console.log("Avatar Preview URL:", previewUrl);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get("/profile");
        const userProfile = response.data;
        setName(userProfile.name || "");
        setEmail(userProfile.email || "");
        setAvatarPreview(userProfile.avatar?.url || null);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <div className="row mt-5">
      <Sidebar />
      <main className="col-md-9 mt-5 offset-sm-3">
        <div className="row container mt-5">
          <div className="col-md-4 container">
            <div className="col-12">
              <label htmlFor="avatar" className="form-label">
                Change Profile Picture
              </label>
              <div className="d-flex gap-2 justify-content-center align-items-center">
                {avatarPreview && (
                  <span className="badge bg-transparent rounded-pill position-relative">
                    <img
                      className="rounded-circle me-1"
                      width="184"
                      height="160"
                      src={avatarPreview}
                      alt="Avatar Preview"
                    />
                  </span>
                )}
                {!avatarPreview && (
                  <span className="badge bg-transparent rounded-pill">
                    <i className="bi bi-person-circle text-dark fs-2 text-display-4" />
                  </span>
                )}
                <button
                  className="btn btn-link p-0 ms-2"
                  type="button"
                  onClick={handleEditIconClick}
                >
                  <i className="bi bi-pencil-square text-primary fs-4"></i>
                </button>
                <input
                  type="file"
                  id="avatarInput"
                  accept=".jpg, .jpeg, .png"
                  style={{ display: "none" }}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-12 mt-3">
                <button
                  className="btn reminder-button-color w-100"
                  type="button"
                  onClick={handleUpdateAvatar}
                >
                  Update Avatar
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-8 container">
            <form className="p-4 p-md-5 needs-validation container" noValidate>
              <div className="col-md-12">
                <h4 className="mb-3">Your Profile</h4>
                <div className="col-12">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    disabled
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      id="password"
                      value={password}
                      // placeholder="enter new password"
                      onChange={handleInputChange}
                    
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3">
                <button
                  className="btn reminder-button-color w-100"
                  type="button"
                  onClick={handleUpdateProfile}
                >
                  Update Profile
                </button>
              </div>
              {errorAlert}
              {successAlert}
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Settings;
