import React, { useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import axios from "axios";

const AddCategoryModal = ({ visible, onCancel, onSave}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleCreateCategory = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const { data } = await axios.post("/category/create", values);
      onSave(values);
      message.success("category created successfully")
      form.resetFields();
      onCancel();
    } catch (error) {
      message.error("Failed to create category")
      console.log("Failed to save category:", error);
      setLoading(false);
    }
  }; 

  return (
    <Modal
      open={visible}
      title="Add Category"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleCreateCategory}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="addCategoryForm">
        <Form.Item
          name="categoryName"
          label="Category Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCategoryModal;
