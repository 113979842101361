import React, { Fragment, useContext, useEffect } from "react";
import { UserContext } from "../../context";
import { useHistory } from "react-router-dom";
import axios from "axios";

const ReminderPage = ({ planCode, children }) => {
  const [state, setState] = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state?.user?.paystack_customer_id) {
          const response = await axios.get(
            `/subscription-details/${state.user.paystack_customer_id}`
          );
          const responseData = response.data;

          console.log("Raw Subscription Data:", responseData); // Log raw data

          if (responseData && responseData.success) {
            const subscriptionDetails =
              responseData.subscriptionDetails || {};
            // setUserSubscription(subscriptionDetails); // Not necessary in this component

            // Trigger subscription check after setting userSubscription
            checkSubscription(subscriptionDetails);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error...
      }
    };

    fetchData();
  }, [state?.user?.paystack_customer_id, setState, history]);

  const checkSubscription = (subscriptionDetails) => {
    if (Array.isArray(subscriptionDetails) && subscriptionDetails.length > 0) {
      const activeSubscription = subscriptionDetails.find(
        (subscription) =>
          subscription.status === "active" &&
          subscription.plan.plan_code === planCode
      );

      if (!activeSubscription) {
        console.log(
          "User does not have the required Active Premium subscription. Redirecting to /plans"
        );
        history.push("/plans");
      } else {
        console.log(
          "User has the required Active Premium subscription. Proceeding..."
        );
      }
    } else {
      console.error("Subscription details array is empty or undefined.");
      history.push("/plans");
    }
  };

  return <Fragment>{children}</Fragment>;
};

export default ReminderPage;
