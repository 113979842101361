import React, { Suspense } from "react";
import { Skeleton } from "antd";
import "./vendorhero.css";
import { Link } from "react-router-dom";

const VendorHero = () => {
  return (
    <div>
      <div className="container px-4 py-1">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-4 col-lg-6">
            <Suspense fallback={<Skeleton.Image />}>
              <img
                src="/assets/images/gift-vendor-runti.jpg"
                className="d-block shadow-lg mx-lg-auto img-fluid vendorImage"
                alt=""
                width={600}
                height={300}
                loading="lazy"
              />
            </Suspense>
          </div>
          <div className="col-lg-6">
            <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3 vendor-heroText">
              Join our gifting <br /> community 🎁
            </h1>
            <p className="lead">
              List, manage and sell your gift packages on runti app <br />
              Lets make gifting and celebration, memorable and fun!!.
            </p>

            <div>
              <Link to="/vendor/register">
                <button className="btn btn-sm vendor-cta-button">
                  Become a vendor
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorHero;
