import React from "react";
import { useHistory } from "react-router-dom";
import "./cards.css"; // Importing cards.css

const PlansCards = ({
  plan,
  handleSubscription,
  userSubscription,
  isAuthenticated,
}) => {
  const history = useHistory();

  const dynamicDescription = () => {
    if (plan.name === "Runti Basic Plan") {
      return "Create 2 reminders";
    } else if (plan.name === "Runti Standard Plan") {
      return "Create 12 reminders";
    } else if (plan.name === "Runti Premium Plan") {
      return "Create 50 reminders";
    }
  };

  const dynamicFeatures = () => {
    if (plan.name === "Runti Basic Plan") {
      return "Get 2 E-Cards";
    } else if (plan.name === "Runti Standard Plan") {
      return "Get 12 E-Cards";
    } else if (plan.name === "Runti Premium Plan") {
      return "Get 50 E-Cards";
    }
  };

  const isUserSubscribedToPlan = () => {
    return userSubscription.plan?.plan_code === plan.plan_code;
  };

  return (
    <div className="col">
      <div className="card mb-4 rounded-3 shadow-lg home-card">
        <div className="card-header py-3">
          <h4 className="my-0 fw-normal plan-card-text-color-2">{plan.name}</h4>
        </div>
        <div className="card-body">
          <h1 className="card-title pricing-card-title plan-card-text-color">
            {plan.currency} {plan.amount / 100}{" "}
            <small className="plan-content-text">/ {plan.interval}</small>
          </h1>
          <ul className="list-unstyled mt-3 mb-4 plan-content-text">
            <li>{dynamicDescription()}</li>
            <li>{dynamicFeatures()}</li>
          </ul>
          {isUserSubscribedToPlan() ? (
            <button
              type="button"
              className="w-100 btn btn-lg plans-button-sub"
              onClick={() => history.push("/dashboard")}
            >
              Access Plan
            </button>
          ) : (
            <button
              type="button"
              className="w-100 btn btn-lg plans-button"
              onClick={(e) => handleSubscription(e, plan)}
            >
              {isAuthenticated ? "Buy This Plan" : "Register"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlansCards;
