import React, { useEffect, useState, useContext, Fragment } from "react";
import VendorSidebar from "../../components/layouts/VendorSidebar.jsx";
import axios from "axios";

const VendorSettings = () => {
  return (
   <Fragment>
   <VendorSidebar />

   <div className="row">
     <main className={`col-lg-8 mx-auto mt-5 container`}>



     </main>


   </div>
 </Fragment>
  )
}

export default VendorSettings
