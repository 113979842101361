import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Card,
  Col,
  message,
  Row,
  Divider,
  Flex,
  Tag,
} from "antd";
import { EyeFilled, ShoppingFilled } from "@ant-design/icons";
import moment from "moment";
import "./productlist.css";
import { TfiGift } from "react-icons/tfi";

const { Meta } = Card;

const ProductList = ({ products = [], discounts = [], categories = [] }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const showModal = (product) => {
    setSelectedProduct(product);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedProduct(null);
  };

  const addToCart = (product) => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    cartItems.push(product);
    message.success("gift dded to Cart");
    localStorage.setItem("cart", JSON.stringify(cartItems));
    const event = new CustomEvent("updateCartCount");
    window.dispatchEvent(event);
  };

  const getProductDiscount = (product) => {
    if (product && discounts) {
      const discount = discounts.find(
        (d) =>
          d.product &&
          d.product._id === product._id &&
          moment().isBefore(moment(d.expiryDate))
      );
      return discount || { discountCode: "None", discountPercentage: 0 };
    }
    return { discountCode: "None", discountPercentage: 0 };
  };

  useEffect(() => {
    console.log(
      "Products or Discounts have been updated:",
      products,
      discounts,
      categories
    );
  }, [products, discounts]);

  useEffect(() => {
    console.log("Selected Product:", selectedProduct);
  }, [selectedProduct]);

  return (
    <div className="container-fluid">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
        {products.map((product) => {
          const productDiscount = getProductDiscount(product);
          const discountedPrice = (
            product.price -
            (product.price * productDiscount.discountPercentage) / 100
          ).toFixed(2);

          return (
            <Col span={8} key={product._id} style={{ marginBottom: 20 }}>
              <Card
                style={{ width: "100%", maxWidth: 250, height: "100%" }}
                className="shadow-lg p-list-card"
                cover={
                  <img
                    alt={product.productName}
                    src={product.productImage?.url || "placeholder.png"}
                    style={{ height: 200, objectFit: "cover" }}
                  />
                }
                actions={[
                  <EyeFilled
                    key="view"
                    onClick={() => showModal(product)}
                    className="eye-icon"
                  />,

                  <TfiGift
                    key="cart"
                    onClick={() => addToCart(product)}
                    className="gift-icon"
                  />,
                ]}
              >
                <Meta
                  title={product.productName}
                  description={
                    <>
                      <span>
                        <strong className="text-dark">Price:</strong>{" "}
                        <p className="text-p-list">₦{discountedPrice}</p>
                      </span>

                      <span>
                        <strong className="text-dark">Categories:</strong>{" "}
                        <p className="text-p-list">
                          {product.categories
                            ?.map((category) => category.categoryName)
                            .join(", ") || "N/A"}
                        </p>
                      </span>

                      <span>
                        <strong className="text-dark">Discount Code:</strong>{" "}
                        <p className="text-p-list">
                          {" "}
                          {productDiscount.discountCode}
                        </p>
                      </span>
                      <span>
                        <strong className="text-dark">
                          Discount Percentage:
                        </strong>{" "}
                        <p className="text-p-list">
                          {" "}
                          {productDiscount.discountPercentage}%
                        </p>
                      </span>
                    </>
                  }
                />
              </Card>
            </Col>
          );
        })}
      </div>
      {selectedProduct && (
        <Modal
          title={selectedProduct.productName}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Close
            </Button>,
          ]}
          width={800} // Adjust width if needed
        >
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className="mb-2 mt-4 px-4 py-3 shadow-md">
                <img
                  src={selectedProduct.productImage?.url}
                  alt={selectedProduct.productName}
                  style={{ width: "100%" }}
                />
                <div className="text-center mt-4 mb-4">
                  <h4>Gift Description</h4>
                  <p>{selectedProduct.description}</p>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div style={{ padding: "10px" }}>
                <Divider />

                <Row gutter={[8, 8]} justify="start">
                  <Col>
                    <Tag bordered={true} color="">
                      Original Price:
                    </Tag>
                  </Col>
                  <Col>
                    <Tag bordered={false} color="" style={{ fontSize: "13px" }}>
                      ₦{selectedProduct.price || "N/A"}
                    </Tag>
                  </Col>
                </Row>

                <Row gutter={[8, 8]} justify="start" className="mt-4">
                  <Col>
                    <Tag bordered={true} color="">
                      Category:
                    </Tag>
                  </Col>
                  <Col>
                    <Tag bordered={false} color="" style={{ fontSize: "13px" }}>
                      {selectedProduct.categories
                        ?.map((category) => category.categoryName)
                        .join(", ") || "N/A"}
                    </Tag>
                  </Col>
                </Row>

                <Divider />

                <Row gutter={[8, 8]} justify="start">
                  <Col>
                    <Tag bordered={true} color="">
                      Quantity:
                    </Tag>
                  </Col>
                  <Col>
                    <Tag bordered={false} color="" style={{ fontSize: "13px" }}>
                      {selectedProduct.quantity || "N/A"}
                    </Tag>
                  </Col>
                </Row>

                <Row gutter={[8, 8]} justify="start" className="mt-4">
                  <Col>
                    <Tag bordered={true} color="">
                      Color:
                    </Tag>
                  </Col>
                  <Col>
                    <Tag bordered={false} color="" style={{ fontSize: "13px" }}>
                      {selectedProduct.color || "N/A"}
                    </Tag>
                  </Col>
                </Row>

                <Divider />

                <Row gutter={[8, 8]} justify="start">
                  <Col>
                    <Tag bordered={true} color="">
                      Size:
                    </Tag>
                  </Col>
                  <Col>
                    <Tag bordered={false} color="" style={{ fontSize: "13px" }}>
                      {selectedProduct.size || "N/A"}
                    </Tag>
                  </Col>
                </Row>

                <Row gutter={[8, 8]} justify="start" className="mt-4">
                  <Col>
                    <Tag bordered={true} color="">
                      Shipping:
                    </Tag>
                  </Col>
                  <Col>
                    <Tag bordered={false} color="" style={{ fontSize: "13px" }}>
                      {selectedProduct.shipping ? "Yes" : "No"}
                    </Tag>
                  </Col>
                </Row>

                <Divider />

                <Row gutter={[8, 8]} justify="start">
                  <Col>
                    <Tag bordered={true} color="">
                      Discount Code:
                    </Tag>
                  </Col>
                  <Col>
                    <Tag
                      bordered={false}
                      color="green"
                      style={{ fontSize: "13px" }}
                    >
                      {getProductDiscount(selectedProduct).discountCode}
                    </Tag>
                  </Col>
                </Row>

                <Row gutter={[8, 8]} justify="start" className="mt-4">
                  <Col>
                    <Tag bordered={true} color="">
                      Discount Percentage:
                    </Tag>
                  </Col>
                  <Col>
                    <Tag
                      bordered={false}
                      color="green"
                      style={{ fontSize: "13px" }}
                    >
                      {getProductDiscount(selectedProduct).discountPercentage}%
                    </Tag>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
};

export default ProductList;
