import axios from "axios";
import React, { useState, useEffect } from "react";
import { SyncOutlined } from "@ant-design/icons";
import ErrorAlert from "../alerts/ErrorAlert";
import SuccessAlert from "../alerts/SuccessAlert";
import moment from "moment-timezone";  // Import moment-timezone here
import "moment-timezone";

const EditReminderModal = ({ reminder, onUpdate }) => {
  const [editedReminder, setEditedReminder] = useState({ ...reminder });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState(null);

  // Open the modal immediately when the component mounts
  useEffect(() => {
    if (reminder) {
      setIsModalOpen(true);
    }
  }, [reminder]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedReminder((prevReminder) => ({
      ...prevReminder,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const lagosDatetime = moment(editedReminder.datetime).tz("Africa/Lagos").format("YYYY-MM-DDTHH:mm:ss");


    try {
      // Check if reminder exists and has datetime property before formatting
      if (reminder && reminder.datetime) {
        editedReminder.datetime = lagosDatetime
      }

      const response = await axios.put(
        `/update/reminder/${reminder._id}`,
        editedReminder
      );
      onUpdate(response.data.updatedReminder);
      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (error) {
      // Handle error
      console.error("Error updating reminder:", error);
      setErrorAlert(<ErrorAlert message="Something went wrong. Try again" />);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };


  const typeValues = [
    "birthday",
    "wedding day",
    "anniversary",
    "graduation",
    "other",
  ];


  return (
    <div>
      {/* Modal */}
      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Reminder
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
            <div className="col-md-7 col-lg-5">
              <form onSubmit={handleSubmit}>
              {errorAlert}
            {successAlert}
                {/* Your form inputs here */}
                <div className="mb-3">
                  <label htmlFor="celebrant" className="form-label">
                    Celebrant
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="celebrant"
                    name="celebrant"
                    value={editedReminder.celebrant}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                      <label htmlFor="dateOfBirth" className="form-label">
                      Celebrant Speicial Day
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={editedReminder.dateOfBirth}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="image" className="form-label">
                        Celebrant Card Image
                      </label>
                      <input
                        type="file"
                        name="image"
                        id="file-input"
                        accept=".jpg,.jpeg,.png"
                        onChange={handleInputChange}
                        className="sr-only"
                      />
                    </div>


                    <div className="mb-3">
                      <label htmlFor="message" className="form-label">
                        Special Message
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        value={editedReminder.message}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="datetime" className="form-label">
                        Set reminder Date time
                      </label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="datetime"
                        name="datetime"
                        value={editedReminder.Datetime}
                        onChange={handleInputChange}
                        required
                      />
                    </div> 

                    <div className="mb-3">
                      <label htmlFor="type" className="form-label">
                        Category
                      </label>
                      <select
                        className="form-select"
                        id="type"
                        name="type"
                        value={editedReminder.type}
                        onChange={handleInputChange}
                        required
                      >
                        {typeValues.map((enumValue) => (
                          <option key={enumValue} value={enumValue}>
                            {enumValue}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="sendNotification"
                        name="sendNotification"
                        checked={editedReminder.sendNotification}
                        onChange={handleInputChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="sendNotification"
                      >
                        Send Notification
                      </label>
                    </div>

                {/* Add other form inputs */}
                <button type="submit" className="btn btn-primary">
                {isLoading ? (<SyncOutlined spin style={{ fontSize: "20px" }} />) : ("Save Changes")}
                </button>
              </form>
            </div>
            </div>
          </div>
        </div>
      </div>
      {/* Background overlay */}
      {isModalOpen && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default EditReminderModal;
