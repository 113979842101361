import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div className="container mt-5">
        <footer className="py-2 text-center">
          <div className="d-flex flex-column flex-sm-row justify-content-center my-2">
            <p className="mb-2">
            <small className="footer-sm-text"> © 2024 Runti App Services -  <span>Dev by SYNC ATH SOLUTION LTD</span></small>
            </p>
            <ul className="list-unstyled d-flex justify-content-center mb-2">
              <li className="ms-3">
                <Link className="link-body-emphasis" to="/">
                  <i className="bi bi-twitter"></i>
                </Link>
              </li>
              <li className="ms-3">
                <Link className="link-body-emphasis" to="/">
                  <i className="bi bi-instagram"></i>
                </Link>
              </li>
              <li className="ms-3">
                <Link className="link-body-emphasis" to="/">
                  <i className="bi bi-facebook"></i>
                </Link>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
