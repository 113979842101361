import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Modal,
  Input,
  Button,
  Form,
  Avatar,
  message,
  Select,
  Spin,
  Empty,
  Upload,
} from "antd";
import axios from "axios";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

const { Meta } = Card;
const { Option } = Select;

const truncateDescription = (description, wordLimit) => {
  if (!description) return "";
  const words = description.split(" ");
  return (
    words.slice(0, wordLimit).join(" ") +
    (words.length > wordLimit ? "..." : "")
  );
};

const ProductCard = ({ product, onEdit, onDelete, discounts = [] }) => {
  const getDiscountedPrice = (product) => {
    if (!discounts) return product.price;

    const discount = discounts.find(
      (d) =>
        d.productId === product._id && moment().isBefore(moment(d.expiryDate))
    );

    if (discount) {
      return (
        product.price - (product.price * discount.discountPercentage) / 100
      );
    }

    return product.price;
  };

  const discountedPrice = getDiscountedPrice(product);

  return (
    <Col span={8} style={{ marginBottom: 20 }}>
      <Card
        style={{
          width: "100%",
          height: "100%",
          maxWidth: 250,
          maxHeight: 600,
        }}
        cover={
          <img
            alt={product.productName}
            src={product.productImage?.url || "placeholder.png"}
          />
        }
        actions={[
          <EditOutlined
            key="edit"
            onClick={() => onEdit("product", product)}
          />,
          <DeleteOutlined
            key="delete"
            onClick={() => onDelete(product._id, "product")}
          />,
        ]}
      >
        <Meta
          title={product.productName}
          description={
            <>
              <p>
                <strong>Price:</strong> ₦{discountedPrice.toFixed(2)}
              </p>
              <p>
                <strong>Description:</strong>{" "}
                {truncateDescription(product.description, 10)}
              </p>
            </>
          }
        />
      </Card>
    </Col>
  );
};

const CategoryCard = ({ category, onEdit, onDelete }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const colSpan = isMobile ? 24 : 8;

  return (
    <Col span={colSpan} style={{ marginBottom: 20 }}>
      <Card
        style={{
          width: "100%",
          height: "100%",
          maxWidth: 250,
          maxHeight: 600,
          border: "1px solid rgb(242, 201, 255)",
          display: "flex",
          flexDirection: "column",
        }}
        className="shadow"
        actions={[
          <EditOutlined
            key="edit"
            onClick={() => onEdit("category", category)}
          />,
          <DeleteOutlined
            key="delete"
            onClick={() => onDelete(category._id, "category")}
          />,
        ]}
      >
        <Meta
          avatar={
            <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />
          }
          title={category.categoryName}
        />
      </Card>
    </Col>
  );
};

const DiscountCard = ({ discount, onEdit, onDelete }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const colSpan = isMobile ? 24 : 8;

  return (
    <Col span={colSpan} style={{ marginBottom: 20 }}>
      <Card
        style={{
          width: "100%",
          height: "100%",
          maxWidth: 250,
          maxHeight: 600,
          border: "1px solid rgb(242, 201, 255)",
          display: "flex",
          flexDirection: "column",
        }}
        className="shadow"
        actions={[
          <EditOutlined
            key="edit"
            onClick={() => onEdit("discount", discount)}
          />,
          <DeleteOutlined
            key="delete"
            onClick={() => onDelete(discount._id, "discount")}
          />,
        ]}
      >
        <Meta
          avatar={
            <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />
          }
          title={discount.discountCode}
          description={
            <>
              <p>
                <strong>Percentage:</strong> {discount.discountPercentage}%
              </p>
            </>
          }
        />
      </Card>
    </Col>
  );
};

const ProductCategoryDiscountUI = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editType, setEditType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [productRes, categoryRes, discountRes] = await Promise.all([
        axios.get("/products/vendor"),
        axios.get("/categories/vendor"),
        axios.get("/discounts/vendor"),
      ]);
      setProducts(productRes.data);
      setCategories(categoryRes.data);
      setDiscounts(discountRes.data);
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error fetching data:", error.response.data);
        // message.error(error.response.data.error || "Error fetching data");
      } else if (error.request) {
        // Request was made but no response received
        console.error("Error fetching data:", error.request);
        // message.error("No response from server");
      } else {
        // Something happened in setting up the request
        console.error("Error fetching data:", error.message);
        // message.error("Error in request setup");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id, type) => {
    setLoading(true);
    try {
      await axios.delete(`/${type}/${id}`);
      message.success("Item deleted successfully");
      fetchData();
    } catch (error) {
      message.error("Error deleting item");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (type, item) => {
    setEditType(type);
    setSelectedItem(item);
    form.setFieldsValue({
      ...item,
      categoryId: item.categories ? item.categories.map((cat) => cat._id) : [],
    });

    if (type === "product" && item.productImage) {
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: item.productImage.url,
          thumbUrl: item.productImage.url,
        },
      ]);
    }
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleImageDelete = async () => {
    if (selectedItem && selectedItem.productImage) {
      setLoading(true);
      try {
        await axios.delete(`/product/image/${selectedItem._id}`);
        message.success("Image deleted successfully");
        setFileList([]);
      } catch (error) {
        message.error("Error deleting image");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSave = async (values) => {
    console.log("Updated values:", values);

    setLoading(true);
    try {
      const id = selectedItem._id;
      let response;

      if (editType === "product") {
        const formData = new FormData();

        if (fileList.length > 0 && fileList[0].originFileObj) {
          formData.append("productImage", fileList[0].originFileObj);
        }

        Object.keys(values).forEach((key) => {
          if (key === "categoryId") {
            formData.append(key, values[key]);
          } else {
            formData.append(key, values[key]);
          }
        });

        response = await axios.put(`/product/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        const updatedProduct = response.data;

        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product._id === id ? updatedProduct : product
          )
        );
      } else if (editType === "category") {
        response = await axios.put(`/category/${id}`, values);
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category._id === id ? { ...category, ...response.data } : category
          )
        );
      } else if (editType === "discount") {
        response = await axios.put(`/discount/${id}`, {
          ...values,
          product: selectedItem.product,
        });
        setDiscounts((prevDiscounts) =>
          prevDiscounts.map((discount) =>
            discount._id === id ? { ...discount, ...response.data } : discount
          )
        );
      }

      message.success("Item updated successfully");
      setSelectedItem(null);
      setEditType(null);
      setFileList([]);
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error("Failed to update item");
    } finally {
      setLoading(false);
    }
  };

  const handleModalCancel = () => {
    setSelectedItem(null);
    setEditType(null);
    form.resetFields();
    setFileList([]);
  };

  const renderEmptyState = () => (
    <Empty
      description={
        <span>
          No items found. Click the <PlusOutlined /> button to add new items.
        </span>
      }
    />
  );

  return (
    <Spin spinning={loading}>
      <div style={{ padding: 20 }}>
        <h3>Products</h3>
        <Row gutter={16}>
          {products.length > 0
            ? products.map((product) => (
                <ProductCard
                  key={product._id}
                  product={product}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  discounts={discounts}
                />
              ))
            : renderEmptyState()}
        </Row>
        <h3>Categories</h3>
        <Row gutter={16}>
          {categories.length > 0
            ? categories.map((category) => (
                <CategoryCard
                  key={category._id}
                  category={category}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              ))
            : renderEmptyState()}
        </Row>
        <h3>Discounts</h3>
        <Row gutter={16}>
          {discounts.length > 0
            ? discounts.map((discount) => (
                <DiscountCard
                  key={discount._id}
                  discount={discount}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              ))
            : renderEmptyState()}
        </Row>

        <Modal
          title={`Edit ${editType}`}
          visible={!!selectedItem}
          onCancel={handleModalCancel}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSave}
            initialValues={selectedItem}
          >
            {editType === "product" && (
              <>
                <Form.Item
                  name="productName"
                  label="Product Name"
                  rules={[{ required: true, message: "Please enter name" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="price"
                  label="Price"
                  rules={[{ required: true, message: "Please enter price" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="quantity"
                  label="Quantity"
                  rules={[{ required: true, message: "Please enter quantity" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    { required: true, message: "Please enter description" },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item name="color" label="Color">
                  <Input />
                </Form.Item>
                <Form.Item name="size" label="Size">
                  <Input />
                </Form.Item>
                <Form.Item name="shipping" label="Shipping">
                  <Select>
                    <Option value="true">Yes</Option>
                    <Option value="false">No</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="categoryId"
                  label="Category"
                  rules={[
                    { required: true, message: "Please select a category" },
                  ]}
                >
                  <Select placeholder="Select a category">
                    {categories.map((category) => (
                      <Option key={category._id} value={category._id}>
                        {category.categoryName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="productImage" label="Product Image">
                  <Upload
                    fileList={fileList}
                    onChange={handleFileChange}
                    beforeUpload={(file) => {
                      setFileList([file]);
                      return false;
                    }}
                    maxCount={1}
                  >
                    <Button icon={<PlusOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
              </>
            )}
            {editType === "category" && (
              <Form.Item
                name="categoryName"
                label="Category Name"
                rules={[{ required: true, message: "Please enter name" }]}
              >
                <Input />
              </Form.Item>
            )}
            {editType === "discount" && (
              <>
                <Form.Item
                  name="discountCode"
                  label="Discount Code"
                  rules={[{ required: true, message: "Please enter code" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="discountPercentage"
                  label="Discount Percentage"
                  rules={[
                    { required: true, message: "Please enter percentage" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="productId"
                  label="Product"
                  rules={[
                    { required: true, message: "Please select a product" },
                  ]}
                >
                  <Select placeholder="Select a product">
                    {products.map((product) => (
                      <Option key={product._id} value={product._id}>
                        {product.productName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="expiryDate"
                  label="Expiry Date"
                  rules={[
                    { required: true, message: "Please select an expiry date" },
                  ]}
                >
                  <Input type="date" />
                </Form.Item>
              </>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Spin>
  );
};

export default ProductCategoryDiscountUI;
