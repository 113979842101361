import React, { useState, useContext } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import axios from "axios";
import { UserContext } from "../../context";
import ErrorAlert from "../../components/alerts/ErrorAlert";
import SuccessAlert from "../../components/alerts/SuccessAlert";
import { Link } from "react-router-dom";
import "./vendorauth.css";

const VendorRegister = ({ history }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [shopAddress, setshopAddress] = useState("");
  const [shopName, setshopName] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [state, setState] = useContext(UserContext);
  const [errorAlert, setErrorAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAvatarInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        setAvatarPreview(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleClick = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const { data } = await axios.post("/vendor/create-shop", {
        name,
        email,
        password,
        avatar,
        shopName,
        shopAddress,
        phoneNumber,
      });

      if (data.error) {
        setErrorAlert(<ErrorAlert message={data.error} />);
      } else {
        setName("");
        setEmail("");
        setPassword("");
        setphoneNumber("");
        setshopName("");
        setshopAddress("");
        setAvatar(null);
        setAvatarPreview(null);
        setSuccessAlert(
          <SuccessAlert
            message={`Hey ${data.user.name}. You are part of the team now. Congrats!`}
          />
        );
        setState(data);
        localStorage.setItem("auth", JSON.stringify(data));
        setTimeout(() => {
          history.push("/vendor/dashboard");
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setErrorAlert(<ErrorAlert message="Something went wrong. Try again" />);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-container">
      <div className="form-container">
        <div className="container py-5">
          <div className="d-flex flex-column justify-content-center align-items-center h-100 text-center">
            <h1 className="display-4 fw-bold lh-1 text-body-emphasis mt-1 text-white">
              Register as a gift vendor
            </h1>
            <p className="col-lg-10 fs-4 text-white">
              Sign up for free. Start Listing.
            </p>
          </div>
          <form
            className="p-2 p-md-5 rounded-3 bg-body-light shadow-lg"
            style={{ border: "2px #86fffd solid" }}
          >
            <div className="form-group">
              {errorAlert}
              {successAlert}
              <Input label="Name" value={name} setValue={setName} />
              <Input
                label="Email"
                type="email"
                value={email}
                setValue={setEmail}
              />
              <Input
                label="Password"
                type="password"
                value={password}
                setValue={setPassword}
              />

              <Input
                label="Shop Name"
                type="text"
                value={shopName}
                setValue={setshopName}
              />

              <Input
                label="Phone number"
                type="text"
                value={phoneNumber}
                setValue={setphoneNumber}
              />
              <Input
                label="Shop Address"
                type="text"
                value={shopAddress}
                setValue={setshopAddress}
              />

              <div className="mb-3">
                <div className="d-flex justify-content-start align-items-center">
                  <label className="form-label fs-7 mt-2 text-white">
                    Shop Logo
                  </label>
                  <span className="mx-2">
                    <input
                      type="file"
                      id="avatar"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleAvatarInputChange}
                      placeholder="Upload shop logo"
                      className="form-control"
                    />
                  </span>
                  {avatarPreview && (
                    <span className="badge bg-transparent rounded-pill">
                      <img
                        className="rounded-circle me-1"
                        width="34"
                        height="34"
                        src={avatarPreview}
                        alt="Avatar Preview"
                      />
                    </span>
                  )}
                  {!avatarPreview && (
                    <span className="badge bg-transparent rounded-pill">
                      <i className="bi bi-person-circle auth-icon-avatar display-6"></i>
                    </span>
                  )}
                </div>
              </div>

              <div className="d-grid auth-button-container mt-3 mb-3">
                <Button
                  handleClick={handleClick}
                  type="primary"
                  text="Create Gift Shop"
                  size="sm"
                  isLoading={isLoading}
                  className="auth-button"
                />
              </div>
            </div>

            <div className="checkbox mb-3 text-center text-white">
              <label>
                <input
                  className="text-white"
                  type="checkbox"
                  defaultValue="remember-me"
                />{" "}
                Remember me
              </label>
            </div>

            <hr className="my-4" />

            <p className="text-body-secondary text-center text-white">
              Already have an account?{" "}
              <Link style={{ color: "#2bd1aa" }} to="/vendor/login">
                Login
              </Link>{" "}
              to your account.
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VendorRegister;
