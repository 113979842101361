import React, { useState, useEffect, Fragment } from "react";
import Sidebar from "../../components/layouts/Sidebar";
import ActivitiesList from "../../components/user/ActivitiesList";
import axios from "axios";
import "./user.css";

const Activities = () => {
  const [totals, setTotals] = useState({
    totalCreatedReminders: 0,
    totalScheduledReminders: 0,
    totalDeletedReminders: 0,
    totalUpdatedReminders: 0,
  });

  useEffect(() => {
    fetchReminders();
  }, []);

  const fetchReminders = async () => {
    try {
      const response = await axios.get("/user/reminders/count");
      const {
        totalCreatedReminders,
        totalScheduledReminders,
        totalDeletedReminders,
        totalUpdatedReminders,
      } = response.data;

      setTotals({
        totalCreatedReminders,
        totalUpdatedReminders,
        totalScheduledReminders,
        totalDeletedReminders,
      });
    } catch (error) {
      console.error("Error fetching reminders:", error);
    }
  };

  return (
    <Fragment>
      <Sidebar />

      <div className="row">
        <main className="col-lg-8 mx-auto mt-5">
          <div className="container mt-5">
            <div className="row mt-5">
                   <div className="col-md-4 mb-4">
                <div className="card h-100 bg-delete text-white shadow">
                  <div className="card-body d-flex flex-column justify-content-center">
                    <i className="bi bi-calendar-check fs-1 mb-3"></i>
                    <h5 className="card-title">Created Reminders</h5>
                    <p className="card-text">{totals.totalCreatedReminders}</p>
                  </div>
                </div>
              </div> 
              <div className="col-md-4 mb-4">
                <div className="card h-100 bg-pending text-white shadow">
                  <div className="card-body d-flex flex-column justify-content-center">
                    <i className="bi bi-clock-history fs-1 mb-3"></i>
                    <h5 className="card-title">Pending Reminders</h5>
                    <p className="card-text">{totals.totalCreatedReminders + totals.totalUpdatedReminders}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card h-100 bg-scheduled text-white shadow">
                  <div className="card-body d-flex flex-column justify-content-center">
                    <i className="bi bi-alarm fs-1 mb-3"></i>
                    <h5 className="card-title">Scheduled Reminders</h5>
                    <p className="card-text">
                      {totals.totalScheduledReminders}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ActivitiesList />
        </main>
      </div>
    </Fragment>
  );
};

export default Activities;
