import React, { useEffect, useState, useContext, Fragment } from "react";
import Sidebar from "../../components/layouts/Sidebar";
import axios from "axios";
import { UserContext } from "../../context";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../components/layouts/Footer";
import ReminderCard from "../../components/cards/ReminderCard";

const Dashboard = () => {
  const [state] = useContext(UserContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const reminderLimits = {
    PLN_iwmm2cbvp6vh1r5: 2,  // Basic Plan
    PLN_l24au1zx0v0uefb: 12, // Standard Plan
    PLN_i8w3ub0yuoa3nec: 50, // Premium Plan
  };

  // //test
  // const reminderLimits = {
  //   PLN_eoas232io967i2s: 2,  // Basic Plan
  //   PLN_dgubydt39gttkfq: 12, // Standard Plan
  //   PLN_e79eim07ury74k9: 50, // Premium Plan
  // };


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state.token && state.user && state.user.paystack_customer_id) {
          console.log("Fetching subscription details...");
          const response = await axios.get(
            `/subscription-details/${state.user.paystack_customer_id}`
          );
          console.log("Subscription details response:", response.data);
          
          const responseData = response.data;

          if (responseData && responseData.success) {
            const subscriptionDetails = responseData.subscriptionDetails || [];
            console.log("Fetched subscriptions:", subscriptionDetails);
            setSubscriptions(subscriptionDetails);
          }
        }

        console.log("Fetching user reminders...");
        const userRemindersResponse = await axios.get("/user/reminders");
        console.log("User reminders response:", userRemindersResponse.data);
        
        const userReminders = userRemindersResponse.data || [];
        setReminders(userReminders.map(reminder => ({
          ...reminder,
          planCode: reminder.subscription?.plan?.plan_code
        })));
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [state.token, state.user, state.user.paystack_customer_id]);

  const handleCreateReminder = (subscriptionData) => {
    if (!subscriptionData || subscriptionData.status !== "active") {
      console.log(
        "User does not have an active subscription. Redirecting to /plans"
      );
      history.push("/plans");
      return;
    }

    const planCode = subscriptionData.plan.plan_code;
    const reminderLimit = reminderLimits[planCode];
    const userRemindersCount = reminders.filter(
      (reminder) => reminder.planCode === planCode
    ).length;

    console.log(
      `Plan Code: ${planCode}, Reminder Limit: ${reminderLimit}, Current Reminders: ${userRemindersCount}`
    );

    if (userRemindersCount >= reminderLimit) {
      console.log("Reminder limit reached for the current plan.");
      return;
    }

    history.push(`/reminder/${planCode}`);
  };

  const isReminderCreationAllowed = (subscriptionData) => {
    if (!subscriptionData || subscriptionData.status !== "active") return false;

    const planCode = subscriptionData.plan.plan_code;
    const reminderLimit = reminderLimits[planCode];
    const userRemindersCount = reminders.filter(
      (reminder) => reminder.planCode === planCode
    ).length;

    console.log(
      `Checking if reminder creation is allowed for Plan Code: ${planCode}`
    );
    console.log(`Reminder Limit: ${reminderLimit}`);
    console.log(`Current Reminders: ${userRemindersCount}`);
    console.log(`All Reminders:`, reminders);

    reminders.forEach((reminder) => {
      console.log(`Reminder: ${reminder._id}, Plan Code: ${reminder.planCode}`);
    });

    return userRemindersCount < reminderLimit;
  };

  const hasSubscriptions = subscriptions && subscriptions.length > 0;
  const hasReminders = reminders && reminders.length > 0;

  return (
    <Fragment>
      <Sidebar />

      <div className="row">
        <main className={`col-lg-8 mx-auto mt-5 container`}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <div className="spinner-border custom-spin" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="container mt-5">
              {hasSubscriptions && (
                <div>
                  <h3 className="text-center mb-4 mt-5">My Subscriptions</h3>
                  <div className="row row-cols-1 row-cols-md-2 g-4">
                    {subscriptions.map((subscription) => (
                      <div className="col" key={subscription.subscription_code}>
                        <div className="card shadow">
                          <div className="card-body">
                            <p className="runti-fonts">
                              {subscription.plan.name} PLAN 🥳
                            </p>
                            <div>
                              <p className="runti-fonts">
                                Subscription Code:{" "}
                                {subscription.subscription_code}
                              </p>
                              <p className="runti-fonts">
                                Subscription Status: {subscription.status}
                              </p>
                              <p className="runti-fonts">
                                Next Payment Date:{" "}
                                {moment(subscription.nextPaymentDate).format("dddd, MMMM Do YYYY h:mm:ss a")}
                              </p>
                            </div>
                            <div className="d-grid gap-1 d-md-flex justify-content-between runti-fonts">
                              <button
                                className="btn reminder-button-color btn-sm shadow"
                                disabled
                              >
                                Manage Plan
                              </button>
                              <button
                                className="btn reminder-button btn-sm shadow"
                                onClick={() =>
                                  handleCreateReminder(subscription)
                                }
                                disabled={
                                  !isReminderCreationAllowed(subscription)
                                }
                              >
                                Create Reminder
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {hasReminders && (
                <div className="mt-5">
                  <h3 className="text-center mb-4 mt-4">My Reminders</h3>
                  <div className="row row-cols-2 row-cols-md-3 g-3">
                    {reminders.map((reminder) => (
                      <ReminderCard
                        key={reminder._id}
                        reminder={reminder}
                        reminders={reminders}
                        setReminders={setReminders}
                      />
                    ))}
                  </div>
                </div>
              )}

              {!hasSubscriptions && !hasReminders && (
                <div className="container mt-5">
                  <div className="position-relative p-3 text-center text-muted bg-body border border-dashed rounded-5 shadow-lg">
                    <h1 className="text-body-emphasis">No Reminder Here 😔</h1>
                    <p className="col-lg-6 mx-auto mb-4 text-dark">
                      Hello{" "}
                      {state && state.user && state.user.name
                        ? `${state.user.name} `
                        : ""}
                      , it looks like you don't have any subscriptions or
                      reminders!!.
                    </p>
                    <Link
                      to="/plans"
                      className="btn reminder-button-color btn-sm px-5 mb-5 shadow-lg"
                    >
                      Buy a plan
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
        </main>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Dashboard;
