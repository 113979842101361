import React, { useState } from "react";
import "./auth.css";
import axios from "axios";
import { message, Form, Input, Button } from "antd";
import { Link } from "react-router-dom";

const PasswordResetRequest = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);

    try {
      const { data } = await axios.post("/password/forgot", { email: values.email });

      if (data.error) {
        message.error(data.error);
      } else {
        message.success("Reset link sent successfully! Please check your mail.");
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error || "Something went wrong. Try again.";
      message.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid bg-body-auth height-100vh">
      <div className="row">
        <div
          className="col-lg-6 p-0"
          style={{
            backgroundImage: "url('/assets/images/runtibgg_new.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
          }}
        ></div>
        <div className="col-lg-6" style={{ backgroundPosition: "center", height: "100vh" }}>
          <div className="container py-5">
            <div className="d-flex flex-column justify-content-center align-items-center h-100 text-center">
              <h1 className="display-4 fw-bold lh-1 text-body-emphasis mt-3 auth-header-text">
                Request Password Reset
              </h1>
              <p className="col-lg-10 fs-4">Let's get you back in!</p>
            </div>
            <Form
              className="p-4 p-md-5 border rounded-3 bg-body-auth shadow"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please enter your email" },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  className="auth-button"
                >
                  Send Reset Link
                </Button>
              </Form.Item>
              <p className="text-body-secondary text-center">
                Remember your password?
                <Link to="/login"> Login</Link>
              </p>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetRequest;
