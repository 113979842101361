import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import GiftShop from "./pages/GiftShop/GiftShop";
import VendorRegister from "./pages/VendorAuth/VendorRegister";
import VendorLogin from "./pages/VendorAuth/VendorLogin";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
// import "./App.css";
import Plans from "./pages/Plans/Plans";
import Dashboard from "./pages/User/Dashboard";
import AuthRoute from "./components/routes/AuthRoute";
import Activities from "./pages/User/Activities";
import Settings from "./pages/User/Settings";
import BasicReminder from "./pages/reminders/BasicRemider";
import StandardReminder from "./pages/reminders/StandardReminder";
import PaystackSuccess from "./pages/PaystackSuccess";
import PaystackCancel from "./pages/PaystackCancel";
import HowItWorks from "./pages/howitworks/HowItWorks";
import PremiumReminder from "./pages/reminders/PremiumReminder";

import PasswordResetRequest from "./pages/auth/PasswordResetRequest.js";
import PasswordReset from "./pages/auth/PasswordReset.js";
import VendorDashboard from "./pages/Vendor/VendorDashboard";
import VendorRoute from "./components/routes/VendorRoute";
import ManageOrders from "./pages/Vendor/ManageOrders";
import ManageProduct from "./pages/Vendor/ManageProduct";
import VendorSettings from "./pages/Vendor/VendorSettings";
import CartPage from "./pages/cart/CartPage";
import Orders from "./pages/User/Orders";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/vendor/register" component={VendorRegister} />
        <Route exact path="/vendor/login" component={VendorLogin} />
        <Route exact path="/" component={Home} />
        <Route exact path="/plans" component={Plans} />
        <Route exact path="/how-it-works" component={HowItWorks} />
        <Route exact path="/gift/shop" component={GiftShop} />
        <Route exact path="/password/forgot" component={PasswordResetRequest} />
        <Route exact path="/password/reset/:token" component={PasswordReset} />
        <Route path="/cart" component={CartPage} />

        <Route exact path="/paystack/success" component={PaystackSuccess} />
        <Route exact path="/paystack/cancel" component={PaystackCancel} />
        
        {/* User Protected Routes */}
        <AuthRoute exact path="/dashboard" component={Dashboard} />
        <AuthRoute exact path="/activities" component={Activities} />
        <AuthRoute exact path="/order/history" component={Orders} />
        <AuthRoute exact path="/settings" component={Settings} />
        <AuthRoute
          exact
          path="/reminder/:planCode"
          render={(props) => {
            const { planCode } = props.match.params;
            if (planCode === "PLN_iwmm2cbvp6vh1r5") {
              return <BasicReminder {...props} />;
            } else if (planCode === "PLN_l24au1zx0v0uefb") {
              return <StandardReminder {...props} />;
            } else if (planCode === "PLN_i8w3ub0yuoa3nec") {
              return <PremiumReminder {...props} />;
            } else return <Redirect to="/plans" />;
          }}
        />

        //Test

        {/* <AuthRoute
          exact
          path="/reminder/:planCode"
          render={(props) => {
            const { planCode } = props.match.params;
            if (planCode === "PLN_eoas232io967i2s") {
              return <BasicReminder {...props} />;
            } else if (planCode === "PLN_dgubydt39gttkfq") {
              return <StandardReminder {...props} />;
            } else if (planCode === "PLN_e79eim07ury74k9") {
              return <PremiumReminder {...props} />;
            } else return <Redirect to="/plans" />;
          }}
        /> */}

        {/* <AuthRoute exact path="/premium" component={PremiumReminder} /> */}
        
        
        {/* Vendor Protected Routes */}
        <VendorRoute
          exact
          path="/vendor/dashboard"
          component={VendorDashboard}
        />
        <VendorRoute exact path="/vendor/orders" component={ManageOrders} />
        <VendorRoute exact path="/vendor/products" component={ManageProduct} />
        <VendorRoute exact path="/vendor/settings" component={VendorSettings} />
      </Switch>
    </Router>
  );
};

export default App;
