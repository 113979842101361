import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Nav from "../../components/layouts/Nav";
import Footer from "../../components/layouts/Footer";
import axios from "axios";
import "./gift.css";
import VendorHero from "../../components/vendorhero/VendorHero.jsx";
import ProductList from "../../components/vendor/productlist/ProductList";
import ProductSearch from "../../components/vendor/product/ProductSearch";

const GiftShop = () => {
  const [products, setProducts] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsResponse = await axios.get("/products");
        const discountsResponse = await axios.get("/discounts");
        const categoriesResponse = await axios.get("/categories");
        setProducts(productsResponse.data);
        setDiscounts(discountsResponse.data);
        setCategories(categoriesResponse.data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = async (searchTerm, category) => {
    try {
      const { data } = await axios.get(`/products/search`, {
        params: { search: searchTerm, category },
      });
      setProducts(data);
    } catch (error) {
      console.error("Failed to search products:", error);
    }
  };

  return (
    <div>
      <Nav />
      <div className="mt-5 mb-5 container">
        <div className="mt-5 mb-5">
          <h1 className="gift-text-color">Gift Ideas</h1>
        </div>
        <ProductSearch onSearch={handleSearch} />
        <ProductList
          products={products}
          discounts={discounts}
          categories={categories}
        />
      </div>
      <div className="mt-5 mb-5">
        <VendorHero />
      </div>
      <Footer />
    </div>
  );
};

export default GiftShop;
