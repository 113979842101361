import React, { useState, useContext } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import axios from "axios";
import { UserContext } from "../../context";
import ErrorAlert from "../../components/alerts/ErrorAlert";
import SuccessAlert from "../../components/alerts/SuccessAlert";
import { Link } from "react-router-dom";
import "./vendorauth.css";

const VendorLogin = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [state, setState] = useContext(UserContext);
  const [errorAlert, setErrorAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);

      const { data } = await axios.post("/vendor/login-shop", {
        email,
        password,
      });

      if (data.error) {
        setErrorAlert(<ErrorAlert message={data.error} />);
      } else {
        setEmail("");
        setPassword("");
        setSuccessAlert(<SuccessAlert message="Login successful!" />);
        localStorage.setItem("auth", JSON.stringify(data));
        setState(data);
        setTimeout(() => {
          history.push("/vendor/dashboard");
        }, 1000);
      }
    } catch (err) {
      setErrorAlert(<ErrorAlert message="Something went wrong. Try again" />);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-container-login">
      <div className="form-container">
        <div className="container py-5">
          <div className="d-flex flex-column justify-content-center align-items-center h-100 text-center">
            <h1 className="display-4 fw-bold lh-1 text-body-emphasis mt-1 text-white">
              Vendor Login
            </h1>
            <p className="col-lg-10 fs-4 text-white">Login to manage shop</p>
          </div>
          <form
            className="p-2 p-md-5 rounded-3 bg-body-light shadow-lg"
            style={{ border: "2px #86fffd solid" }}
          >
            <div className="form-group">
              {errorAlert}
              {successAlert}

              <Input
                label="Email"
                type="email"
                value={email}
                setValue={setEmail}
              />
              <Input
                label="Password"
                type="password"
                value={password}
                setValue={setPassword}
              />

              <div className="d-grid auth-button-container mt-3 mb-3">
                <Button
                  handleClick={handleClick}
                  type="primary"
                  text="Login"
                  size="sm"
                  isLoading={isLoading}
                  className="auth-button"
                />
              </div>
            </div>

            <div className="checkbox mb-3 text-center text-white">
              <label>
                <input
                  className="text-white"
                  type="checkbox"
                  defaultValue="remember-me"
                />{" "}
                Remember me
              </label>
            </div>

            <hr className="my-4" />

            <p className="text-body-secondary text-center text-white">
              No Vendor account?{" "}
              <Link style={{ color: "#2bd1aa" }} to="/vendor/register">
                Register
              </Link>{" "}
              to your account.
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VendorLogin;
