import React, { useContext, useState } from "react";
import "./auth.css";
import { UserContext } from "../../context";
import axios from "axios";
import { message, Form, Input, Button, Checkbox } from "antd";
import { Link, useHistory } from "react-router-dom";

const Login = () => {
  const [state, setState] = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async (values) => {
    setIsLoading(true);

    try {
      const { data } = await axios.post("/login", {
        email: values.email,
        password: values.password,
      });

      if (data.error) {
        message.error(data.error);
      } else {
        message.success("Login successful!");
        localStorage.setItem("auth", JSON.stringify(data));
        setState(data);
        setTimeout(() => {
          history.push("/dashboard");
        }, 1000);
      }
    } catch (err) {
      message.error("Something went wrong. Try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid bg-body-auth height-100vh">
      <div className="row">
        <div
          className="col-lg-6 p-0"
          style={{
            backgroundImage: "url('/assets/images/auth-runti.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
          }}
        ></div>
        <div className="col-lg-6" style={{ backgroundPosition: "center", height: "100vh" }}>
          <div className="container py-5">
            <div className="d-flex flex-column justify-content-center align-items-center h-100 text-center">
              <h1 className="display-4 fw-bold lh-1 text-body-emphasis mt-3 auth-header-text">
                Login
              </h1>
              <p className="col-lg-10 fs-4">
                Access your subscriptions. Anytime. Anywhere.
              </p>
            </div>
            <Form
              className="p-4 p-md-5 border rounded-3 bg-body-auth shadow"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please enter your email" },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please enter your password" }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  className="auth-button"
                >
                  Login
                </Button>
              </Form.Item>
              <p className="text-body-secondary text-center">
                Forgotten password?
                <Link to="/password/forgot"> Reset Password</Link>
              </p>
              <p className="text-body-secondary text-center">
                Not a member? <Link to="/register">Register</Link>
              </p>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
