import React, { useContext, useEffect, useState } from "react";
import { Table, Button, Modal, message, Row, Col, Image, Tag } from "antd";
import { EyeOutlined, DownloadOutlined, CopyOutlined } from "@ant-design/icons";
import axios from "axios";
import { UserContext } from "../../context";
import "./orderhistory.css";

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userState] = useContext(UserContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        
        const response = await axios.get("/order/user/order", { 
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
        });
        setOrders(response.data);
      } catch (error) {
        message.error("Failed to load orders.");
      } finally {
        setLoading(false);
      }
    };

    if (userState.token) {
      fetchOrders();
    }
  }, [userState.token]);

  const columns = [
    {
      title: "Order ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => `₦${(text + record.deliveryFee).toFixed(2)}`,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => handleViewOrder(record._id)}
          >
            View
          </Button>
          <Button
            type="link"
            icon={<DownloadOutlined />}
            onClick={() => handleDownloadInvoice(record._id)}
          >
            Download Invoice
          </Button>
        </div>
      ),
    },
  ];

  const handleViewOrder = async (orderId) => {
    try {
      const response = await axios.get(`/order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${userState.token}`,
        },
      });
      setSelectedOrder(response.data);
      setIsModalVisible(true);
    } catch (error) {
      message.error("Failed to load order details.");
    }
  };

  const handleDownloadInvoice = async (orderId) => {
    try {
      setLoading(true); // Show loading indicator
      const response = await axios.get(`/order/invoice/${orderId}/download`, {
        headers: {
          Authorization: `Bearer ${userState.token}`,
        },
        responseType: "blob", // Ensure the response is handled as a binary blob
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Invoice_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      message.success("Invoice downloaded successfully.");
    } catch (error) {
      message.error("Failed to download invoice.");
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedOrder(null);
  };

  const handleCopyOrderId = (orderId) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(orderId).then(
        () => {
          message.success("Order ID copied to clipboard!");
        },
        (err) => {
          console.error("Failed to copy: ", err);
          message.error("Failed to copy Order ID.");
        }
      );
    } else {
      console.error("Clipboard API not supported.");
      message.error("Clipboard API not supported.");
    }
  };

  return (
    <div className="mt-5 table-responsive">
      <h3>Your Order History</h3>
      <Table
        columns={columns}
        dataSource={orders}
        rowKey="_id"
        loading={loading}
        pagination={{ pageSize: 5 }}
      />

      <Modal
        title="Order Details"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {selectedOrder && (
          <>
            <Row gutter={[24, 24]} style={{ marginBottom: 16 }}>
              <Col span={16}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <b className="order-details-header">Order ID:</b>
                  <span style={{ marginLeft: 8 }}>{selectedOrder._id}</span>
                  <CopyOutlined
                    style={{ marginLeft: 8, cursor: "pointer" }}
                    onClick={() => handleCopyOrderId(selectedOrder._id)}
                  />
                </div>
              </Col>
              <Col span={16}>
                <div>
                  <Tag bordered={true} color="" style={{ fontSize: "13px" }}>
                    <b className="order-details-header">Status:</b>{" "}
                    {selectedOrder.status}
                  </Tag>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 24]} style={{ marginBottom: 16 }}>
              <Col span={12}>
                <div>
                  <b className="order-details-header">Date:</b>{" "}
                  {new Date(selectedOrder.createdAt).toLocaleDateString()}
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <b className="order-details-header">Delivery Fee:</b> ₦
                  {selectedOrder.deliveryFee.toFixed(2)}
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 24]} style={{ marginBottom: 16 }}>
              <Col span={12}>
                <div>
                  <b className="order-details-header">Total Amount:</b> ₦
                  {(selectedOrder.amount + selectedOrder.deliveryFee).toFixed(
                    2
                  )}
                </div>
              </Col>
            </Row>

            <h4
              className="order-details-header"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              Products:
            </h4>

            {selectedOrder.products.map((item, index) => (
              <Row
                key={index}
                gutter={[8, 8]}
                style={{
                  marginBottom: 10,
                  marginTop: 18,
                  padding: "10px",
                  borderBottom: "2px solid #f0f0f0",
                }}
              >
                <Col span={8}>
                  <Image
                    width={100}
                    height={100}
                    style={{ objectFit: "cover", borderRadius: 4 }}
                    src={
                      item.product.productImage?.url || "fallback_image_url.jpg"
                    }
                    alt={item.product.productName}
                  />
                </Col>
                <Col span={8}>
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <div>
                        <b className="order-details-header">Product Name:</b>{" "}
                        {item.product.productName}
                      </div>
                    </Col>
                    <Col span={24}>
                      <div>
                        <b className="order-details-header">Quantity:</b>{" "}
                        {item.quantity}
                      </div>
                    </Col>
                    <Col span={24}>
                      <div>
                        <b className="order-details-header">Price:</b> ₦
                        {item.price.toFixed(2)}
                      </div>
                    </Col>
                  </Row>
                  {/* {item.product.categories && (
                    <Row gutter={[16, 24]} style={{ marginTop: 18 }}>
                      <Col span={24}>
                        <div>
                          <b>Categories:</b>{" "}
                          {item.product.categories
                            .map((category) => category.name)
                            .join(", ") || "N/A"}
                        </div>
                      </Col>
                    </Row>
                  )} */}
                </Col>
              </Row>
            ))}
          </>
        )}
      </Modal>
    </div>
  );
};

export default OrderHistory;
