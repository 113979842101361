import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import PlansCards from "../../components/cards/PlansCards";
import Nav from "../../components/layouts/Nav";
import Hero from "../../components/hero/Hero";
import NewsLetter from "../../components/layouts/NewsLetter";
import { UserContext } from "../../context";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import "./plans.css"; // Importing plans.css

const Plans = () => {
  const [state] = useContext(UserContext);
  const [plans, setPlans] = useState([]);
  const [userSubscription, setUserSubscription] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchPlans();
        if (state?.user?.paystack_customer_id) {
          await fetchUserSubscription(state.user.paystack_customer_id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Error fetching data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [state?.user?.paystack_customer_id]);

  const fetchPlans = async () => {
    try {
      const response = await axios.get("/get-plans");
      const responseData = response.data;
      console.log("Plans response data:", responseData);

      if (!responseData?.success || !Array.isArray(responseData.plans)) {
        throw new Error("Invalid data format for plans");
      }
      setPlans(responseData.plans);
    } catch (error) {
      console.error("Error fetching plans", error);
      message.error("Error fetching plans. Please try again.");
    }
  };

  const fetchUserSubscription = async (paystackCustomerId) => {
    try {
      const response = await axios.get(
        `/subscription-details/${paystackCustomerId}`
      );
      const responseData = response.data;
      console.log("Subscription details response:", responseData);

      if (responseData?.success) {
        setUserSubscription(responseData.subscriptionDetails[0] || {});
      }
    } catch (error) {
      console.error("Error fetching subscriptions", error);
      // message.error("Error fetching subscriptions. Please try again.");
    }
  };

  const handleSubscription = async (e, plan) => {
    e.preventDefault();
    try {
      if (!state?.token || !state?.user?.paystack_customer_id) {
        console.error(
          "User data not available or paystack_customer_id is missing."
        );
        history.push("/register");
        return;
      }

      const planCode = plan.plan_code;
      if (!planCode) {
        console.error("Plan code is required.");
        return;
      }

      if (userSubscription.plan?.plan_code === planCode) {
        history.push("/dashboard");
        message.success(
          "Your subscription is already active. Redirecting to the dashboard."
        );
        return;
      }

      const initializeResponse = await axios.post(
        "/initialize-transaction-with-plan",
        { planCode }
      );
      const authorizationUrl = initializeResponse.data.authorization_url;
      const transactionReference =
        initializeResponse.data.authorizationDetails.reference;

      if (!authorizationUrl) {
        setTimeout(() => {
          history.push("/paystack/cancel");
        }, 1000);
        console.error("Failed to initialize transaction");
        return;
      }

      // Redirect to Paystack for payment
      window.location.href = authorizationUrl;

      // Poll the server to verify the payment status
      const pollingInterval = setInterval(async () => {
        try {
          const verifyResponse = await axios.get(
            `/verify-transaction/${transactionReference}`
          );
          if (verifyResponse.data.success) {
            clearInterval(pollingInterval);
            const createSubscriptionResponse = await axios.post(
              "/create-subscription",
              { plan_code: planCode }
            );
            const subscriptionDetails =
              createSubscriptionResponse.data.subscriptionDetails;

            if (subscriptionDetails) {
              message.success("Your subscription was successful");
              // Redirect after successful subscription
              history.push("/dashboard");
            } else {
              message.error("Failed to create subscription");
            }
          } else if (
            verifyResponse.data.message === "Payment failed or cancelled"
          ) {
            clearInterval(pollingInterval);
            // Redirect to PaystackCancel component
            history.push("/paystack/cancel");
          }
        } catch (error) {
          console.error("Error verifying transaction:", error);
        }
      }, 5000); // Check every 5 seconds
    } catch (error) {
      console.error("Error handling subscription:", error);
      message.error("Error handling subscription");
    }
  };

  return (
    <div>
      <Nav />
      <Hero />
      <div className="container pricing-header mt-3 mb-5 p-3 pb-md-4 mx-auto text-center">
        <h1 className="display-4 fw-normal plans-text-color">Our Plans</h1>
        <p className="fs-5 text-body-dark">
          Buy a plan today and get access to all the features of the platform.
        </p>
      </div>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
          {plans.map((plan) => (
            <PlansCards
              key={plan.id}
              plan={plan}
              handleSubscription={handleSubscription}
              userSubscription={userSubscription}
              isAuthenticated={!!state?.token}
            />
          ))}
        </div>
      </div>
      <div className="container mb-5 mt-5">
        <h2 className="display-6 text-center plans-text-color mb-4 mt-5">
          Compare Plans
        </h2>
        <div className="table-responsive">
          <table className="table plans-text-color text-center">
            <thead>
              <tr>
                <th style={{ width: "34%" }} />
                <th style={{ width: "22%" }}>Basic</th>
                <th style={{ width: "22%" }}>Standard</th>
                <th style={{ width: "22%" }}>Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  Customized E-cards
                </th>
                <td>
                  <i className="bi bi-check" />
                </td>
                <td>
                  <i className="bi bi-check" />
                </td>
                <td>
                  <i className="bi bi-check" />
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Reminder Notifications
                </th>
                <td>
                  <i className="bi bi-check" />
                </td>
                <td>
                  <i className="bi bi-check" />
                </td>
                <td>
                  <i className="bi bi-check" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <NewsLetter />
    </div>
  );
};

export default Plans;
