import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from '../../context';

const ActivitiesList = () => {
  const [activities, setActivities] = useState([]);
  const [userState] = useContext(UserContext);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get('/user-activities');
        setActivities(response.data);
      } catch (error) {
        console.error('Error fetching user activities:', error);
      }
    };

    if (userState.token) {
      fetchActivities();
    }
  }, [userState.token]);

  return (
    <div className='container'>
      <div className="my-3 p-3 bg-body rounded shadow-sm">
        <h6 className="border-bottom pb-2 mb-0">Recent updates</h6>
        {activities.map((activity, index) => (
          <div key={index} className="d-flex text-body-secondary pt-3">
            <p className="pb-3 mb-0 small lh-sm border-bottom">
              <strong className="d-block text-gray-dark">@{activity}</strong>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivitiesList;
