// ReminderCard.js
import React, { useState } from "react";
import axios from "axios";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import "./cards.css";
import moment from "moment-timezone";
import EditReminderModal from "../modals/EditReminderModal";

const ReminderCard = ({ reminder, reminders, setReminders}) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleDeleteReminder = async () => {
    try {
      await axios.delete(`/delete/reminder/${reminder._id}`);
      // Handle successful deletion

      // Remove the deleted reminder from the list
      const updatedReminders = reminders.filter((rem) => rem._id !== reminder._id);
      setReminders(updatedReminders); // Update the list of reminders
      setSuccessMessage("Reminder deleted successfully");
      
    } catch (error) {
      // Handle error if needed
      console.error("Error deleting reminder:", error);
      setErrorMessage("Failed to delete reminder");
    } finally {
      // Close the popover
      setShowPopover(false);
    }
  };

  const handleCancelDelete = () => {
    // Close the popover without deleting the reminder
    setShowPopover(false);
  };

  return (
    <div className="col">
      <div className="card shadow h-100 d-flex flex-column justify-content-between bg-plan-card-details">
        <img
          src={reminder.image.url}
          className="card-img-top"
          alt="Reminder Celebrant"
          style={{ objectFit: "contain" }}
        />
        <div className="card-body">
          <h5 className="card-title runti-fonts">
            Celebrate: {reminder.celebrant}
          </h5>
          <p className="card-text runti-fonts">Wishes: {reminder.message}</p>
          {reminder.datetime && (
            <p className="card-text runti-fonts">
              Remind me on:{" "}
              {moment(reminder.datetime)
                .tz("Africa/Lagos")
                .subtract(1, "hours")
                .format("MMMM Do, YYYY h:mm A")}
            </p>
          )}
          <p className="card-text runti-fonts">Type: {reminder.type}</p>
        </div>
        <div className="card-footer d-flex justify-content-between">
          <i
            className="bi bi-pencil text-success"
            style={{ cursor: "pointer" }}
            onClick={handleEditClick}
          ></i>

          <>
            <i
              className="bi bi-trash text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => setShowPopover(true)}
            ></i>
            {showPopover && (
              <div className="popover">
                <div className="popover-content">
                  <p>Are you sure you want to delete this reminder?</p>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-danger me-2"
                      onClick={handleDeleteReminder}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={handleCancelDelete}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>

      {isModalOpen && (
        <EditReminderModal reminder={reminder} onUpdate={handleModalClose} />
      )}

      {successMessage && <SuccessAlert message={successMessage} />}
      {errorMessage && <ErrorAlert message={errorMessage} />}
    </div>
  );
};

export default ReminderCard;
