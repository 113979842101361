import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Card,
  Descriptions,
  Select,
  message,
} from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context";

const { Option } = Select;

const Checkout = ({
  cartItems,
  selectedState,
  deliveryFees,
  setSelectedState,
}) => {
  const [form] = Form.useForm();
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [state, setState] = useContext(UserContext);

  useEffect(() => {
    if (selectedState) {
      const fee = deliveryFees.find((fee) => fee.state === selectedState);
      setDeliveryFee(fee ? fee.fee : 0);
    }
  }, [selectedState, deliveryFees]);

  const getTotalWithDiscount = () => {
    const total = cartItems.reduce(
      (total, item) =>
        total +
        item.quantity *
          (item.price - item.price * (item.discountPercentage / 100)),
      0
    );
    return total;
  };

  const handleFinish = async (values) => {
    setLoading(true);

    const totalAmount = getTotalWithDiscount() + deliveryFee;

    if (isNaN(totalAmount)) {
      message.error(
        "Invalid amount calculation. Please check your cart and delivery fee."
      );
      setLoading(false);
      return;
    }

    const orderDetails = {
      ...values,
      deliveryFee,
      cartItems,
      amount: totalAmount,
    };

    try {
      // Step 1: Create the Order
      const orderResponse = await axios.post("/order/create", orderDetails);

      if (orderResponse.data.success) {
        // Redirect to Payment Gateway
        window.location.href = orderResponse.data.authorization_url;
      } else {
        message.error("Failed to initialize payment. Please try again.");
        history.push("/paystack/cancel");
      }
    } catch (error) {
      console.error("Error placing order:", error);
      message.error("An error occurred, please try again.");
      history.push("/paystack/cancel");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="checkout-card">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        className="checkout-form"
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Full Name"
              name="name"
              rules={[
                { required: true, message: "Please enter your full name" },
              ]}
            >
              <Input placeholder="Enter your full name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { required: true, message: "Please enter your email address" },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input placeholder="Enter your email address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                { required: true, message: "Please enter your phone number" },
              ]}
            >
              <Input placeholder="Enter your phone number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Shipping Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please enter your shipping address",
                },
              ]}
            >
              <Input placeholder="Enter your shipping address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: true, message: "Please select your state" }]}
            >
              <Select
                placeholder="Select your state"
                onChange={setSelectedState}
              >
                {deliveryFees.map((fee) => (
                  <Option key={fee.state} value={fee.state}>
                    {fee.state}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Additional Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please enter an additional message",
                },
              ]}
            >
              <Input.TextArea placeholder="Enter any additional message" />
            </Form.Item>
          </Col>
        </Row>
        <Descriptions
          column={1}
          layout="horizontal"
          className="text-center"
          style={{ textAlign: "center", justifyContent: "center" }}
        >
          <Descriptions.Item
            label={
              <span style={{ color: "#000000", fontWeight: 200 }}>
                Total Products:
              </span>
            }
            contentStyle={{
              color: "#24A7A4",
              fontWeight: "bolder",
              justifyContent: "center",
            }}
          >
            {cartItems.reduce((total, item) => total + item.quantity, 0)}
          </Descriptions.Item>

          <Descriptions.Item
            label={
              <span style={{ color: "#000000", fontWeight: 200 }}>
                Total Price Before Discount:
              </span>
            }
            contentStyle={{
              color: "#24A7A4",
              fontWeight: "bolder",
              justifyContent: "center",
            }}
          >
            ₦
            {cartItems
              .reduce((total, item) => total + item.quantity * item.price, 0)
              .toFixed(2)}
          </Descriptions.Item>

          <Descriptions.Item
            label={
              <span style={{ color: "#000000", fontWeight: 200 }}>
                Total Price After Discount:
              </span>
            }
            contentStyle={{
              color: "#24A7A4",
              fontWeight: "bolder",
              justifyContent: "center",
            }}
          >
            ₦{getTotalWithDiscount().toFixed(2)}
          </Descriptions.Item>

          <Descriptions.Item
            label={
              <span style={{ color: "#000000", fontWeight: 200 }}>
                Delivery Fee:
              </span>
            }
            contentStyle={{
              color: "#24A7A4",
              fontWeight: "bolder",
              justifyContent: "center",
            }}
          >
            ₦{deliveryFee.toFixed(2)}
          </Descriptions.Item>

          <Descriptions.Item
            label={
              <span style={{ color: "#000000", fontWeight: 200 }}>
                Total Amount:
              </span>
            }
            contentStyle={{
              color: "#24A7A4",
              fontWeight: "bolder",
              justifyContent: "center",
            }}
          >
            ₦{(getTotalWithDiscount() + deliveryFee).toFixed(2)}
          </Descriptions.Item>
        </Descriptions>

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading}
          style={{
            width: "100%",
            backgroundColor: "#24A7A4",
            fontWeight: "bold",
            justifyContent: "center",
          }}
        >
          Place Order
        </Button>
      </Form>
    </Card>
  );
};

export default Checkout;
