import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Nav from "../../components/layouts/Nav";
import Footer from "../../components/layouts/Footer";
import NewsLetter from "../../components/layouts/NewsLetter";
import HomeCards from "../../components/cards/HomeCards";
import { UserContext } from "../../context";
import axios from "axios";
import "./home.css";
const Home = () => {
  const [state, setState] = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        if (
          state &&
          state.token &&
          state.user &&
          state.user.paystack_customer_id
        ) {
          const response = await axios.get(
            `/subscription-details/${state.user.paystack_customer_id}`
          );
          const responseData = response.data;
          if (responseData && responseData.success) {
            const subscriptionDetails = responseData.subscriptionDetails || [];
            const hasActiveSub = subscriptionDetails.some(
              (subscription) => subscription.status === "active"
            );
            setHasActiveSubscription(hasActiveSub);
          }
        }
      } catch (error) {
        console.error("Error fetching subscription details", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscriptionDetails();
  }, [state]);

  return (
    <div>
      {/* <div>
        <h1 className="display-1 text-center">DownTime</h1>
      </div> */}

      <Nav />

      {/* Jumbotron */}
      <div className="px-3 pt-3 my-3 text-center">
        <h1 className="display-4 fw-bold home-text-color">
          Never Forget Your Loved Ones <br /> Special Moments!!
        </h1>
        <div className="col-lg-6 mx-auto"></div>

        <div className="overflow-hidden" style={{ maxHeight: "90vh" }}>
          <div className="container px-5">
            <img
              src="/assets/images/heroimage.jpeg"
              className="img-fluid rounded-3 shadow-lg mb-4"
              alt="runti img"
              width={500}
              height={300}
              loading="lazy"
            />
          </div>
        </div>

        {/* Conditional rendering based on user's authentication and subscription status */}

        {isLoading ? (
          <span className="spinner-border spinner-border-sm mt-2" role="status">
            <span className="visually-hidden">Loading...</span>
          </span>
        ) : state && state.token ? (
          hasActiveSubscription ? (
            <Link
              to="/dashboard"
              className="btn home-button mt-4 btn-sm px-4 me-sm-3"
            >
              Dashboard
            </Link>
          ) : (
            <Link
              to="/plans"
              className="btn home-button mt-4 btn-sm px-4 me-sm-3"
            >
              Buy a plan
            </Link>
          )
        ) : (
          <Link
            to="/register"
            className="btn home-button mt-4 btn-sm px-4 me-sm-3"
          >
            Register now
          </Link>
        )}

        <Link
          to="/how-it-works"
          className="btn home-button-color mt-4 btn-sm px-4"
        >
          Learn more
        </Link>
      </div>

      <HomeCards />

      <NewsLetter />
      <Footer />
    </div>
  );
};

export default Home;
