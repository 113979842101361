import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Spin, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import axios from 'axios';
import { UserContext } from '../context';

const PaystackSuccess = () => {
  const { state: { user } } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const reference = new URLSearchParams(location.search).get('reference');

  useEffect(() => {
    if (reference) {
      verifyTransaction();
    }
  }, [reference]);

  const verifyTransaction = async () => {
    try {
      const { data } = await axios.get(`/verify-transaction/${reference}`);
      if (data.success) {
        message.success('Payment successful!');
        history.push('/dashboard'); // Redirect to the dashboard on success
      } else {
        message.error(data.message);
        history.push('/paystack/cancel');
      }
    } catch (error) {
      console.error('Error verifying transaction:', error);
      message.error('Error verifying transaction');
      history.push('/paystack/cancel');
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-center align-items-center vh-100" style={{ marginTop: '-5%' }}>
        <Spin indicator={<SyncOutlined style={{ fontSize: 50 }} spin />} />
      </div>
    </div>
  );
};

export default PaystackSuccess;
