import React, { useEffect, useState, useContext, Fragment } from "react";
import VendorSidebar from "../../components/layouts/VendorSidebar.jsx";
import axios from "axios";
import { Button, Spin, message } from "antd";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context";
import {
  InboxOutlined,
  DollarCircleOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import DashboardCard from "../../components/vendor/dashboardcard/DashboardCard.jsx";
import { useMediaQuery } from "react-responsive";
import AddProductModal from "../../components/vendor/product/AddProductModal";
import AddCategoryModal from "../../components/vendor/product/AddCategoryModal";
import AddDiscountModal from "../../components/vendor/product/AddDiscountModal";
import ProductCategoryDiscountUI from "../../components/vendor/product/ProductCategoryDiscountUI.jsx";

const ManageProduct = ({
  handleCreateProduct,
  handleCreateCategory,
  handleCreateDiscount,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state] = useContext(UserContext);
  const history = useHistory();
  const [isProductModalVisible, setIsProductModalVisible] = useState(false);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [isDiscountModalVisible, setIsDiscountModalVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [discounts, setDiscounts] = useState([]);

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  const cardsData = [
    {
      icon: <InboxOutlined style={{ fontSize: "24px", color: "#1890ff" }} />,
      title: "Total Products",
      value: products.length,
    },
    {
      icon: <WarningOutlined style={{ fontSize: "24px", color: "#1890ff" }} />,
      title: "Out Of Stock",
      value: products.filter((product) => product.quantity === 0).length,
    },
    {
      icon: (
        <CheckCircleOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
      ),
      title: "In Stock",
      value: products.filter((product) => product.quantity > 0).length,
    },
    {
      icon: (
        <DollarCircleOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
      ),
      title: "Sold Products",
      value: products.filter((product) => product.sold).length,
    },
  ];

  const fetchData = async () => {
    console.log("Fetching data...");
    setIsLoading(true);
    try {
      const [productRes, categoryRes, discountRes] = await Promise.all([
        axios.get("/products/vendor"),
        axios.get("/categories/vendor"),
        axios.get("/discounts/vendor"),
      ]);
      setProducts(productRes.data);
      setCategories(categoryRes.data);
      setDiscounts(discountRes.data);
      console.log("Data fetched successfully");
    } catch (error) {
      message.error("Error fetching data");
      console.error("Error fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDataChange = async (action, type) => {
    console.log(`Handling data change for ${type}...`);
    setIsLoading(true);
    try {
      await action();
      console.log(`${type} created successfully`);
      await fetchData();
      message.success("Item created successfully");
    } catch (error) {
      // message.error("Operation failed");
      console.error("Error creating item", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <VendorSidebar />

      <div className="row">
        <main className={`col-lg-8 mx-auto mt-5 container-fluid`}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <div className="container mt-5">
              <div className="d-flex justify-content-between align-items-center mb-4 mt-5">
                <h3>Manage Products</h3>
                {isDesktopOrLaptop && (
                  <div className="d-flex justify-content-end">
                    <Button
                      type="primary"
                      className="me-2"
                      onClick={() => setIsProductModalVisible(true)}
                    >
                      Add Product
                    </Button>
                    <Button
                      type="default"
                      className="me-2"
                      onClick={() => setIsCategoryModalVisible(true)}
                    >
                      Add Category
                    </Button>
                    <Button
                      type="default"
                      onClick={() => setIsDiscountModalVisible(true)}
                    >
                      Add Discount
                    </Button>
                  </div>
                )}
              </div>

              <div className="container mt-5 mb-5">
                <hr style={{ width: "100%" }} />

                {isTabletOrMobile && (
                  <div className="d-flex justify-content-end mb-3">
                    <Button
                      type="primary"
                      className="me-2"
                      onClick={() => setIsProductModalVisible(true)}
                    >
                      Add Product
                    </Button>
                    <Button
                      type="default"
                      className="me-2"
                      onClick={() => setIsCategoryModalVisible(true)}
                    >
                      Add Category
                    </Button>
                    <Button
                      type="default"
                      onClick={() => setIsDiscountModalVisible(true)}
                    >
                      Add Discount
                    </Button>
                  </div>
                )}
              </div>

              <div className="row row-cols-1 row-cols-md-2 g-4">
                {cardsData.map((card, index) => (
                  <DashboardCard
                    key={index}
                    icon={card.icon}
                    title={card.title}
                    value={card.value}
                  />
                ))}
              </div>
              <div>
                <ProductCategoryDiscountUI
                  products={products}
                  categories={categories}
                  discounts={discounts}
                />
              </div>
            </div>
          )}
        </main>
      </div>

      <AddProductModal
        visible={isProductModalVisible}
        onCancel={() => setIsProductModalVisible(false)}
        onSave={() => handleDataChange(handleCreateProduct, "product")}
      />

      <AddCategoryModal
        visible={isCategoryModalVisible}
        onCancel={() => setIsCategoryModalVisible(false)}
        onSave={() => handleDataChange(handleCreateCategory, "category")}
      />

      <AddDiscountModal
        visible={isDiscountModalVisible}
        onCancel={() => setIsDiscountModalVisible(false)}
        onSave={() => handleDataChange(handleCreateDiscount, "discount")}
      />
    </Fragment>
  );
};

export default ManageProduct;
