import React, { useEffect, useState, useContext, Fragment } from "react";
import VendorSidebar from "../../components/layouts/VendorSidebar.jsx";
import axios from "axios";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../components/layouts/Footer";
import { UserContext } from "../../context";
import {
  UserOutlined,
  ShoppingOutlined,
  MessageOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import DashboardCard from "../../components/vendor/dashboardcard/DashboardCard.jsx";

const VendorDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state] = useContext(UserContext);
  const history = useHistory();

  const cardsData = [
    {
      icon: <DollarCircleOutlined  style={{ fontSize: "24px", color: "#1890ff" }} />,
      title: "Total Sales",
      value: "₦0", 
    },
    {
      icon: <UserOutlined style={{ fontSize: "24px", color: "#1890ff" }} />,
      title: "Total Customers",
      value: "0",
    },
    {
      icon: <MessageOutlined  style={{ fontSize: "24px", color: "#1890ff" }} />,
      title: "Product Reviews",
      value: "0", 
    },
    {
      icon: <ShoppingOutlined style={{ fontSize: "24px", color: "#1890ff" }} />,
      title: "Total Products",
      value: "0",
    },
  ];

  return (
    <Fragment>
      <VendorSidebar />

      <div className="row">
        <main className={`col-lg-8 mx-auto mt-5 container`}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <div className="spinner-border custom-spin" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="container mt-5">
              <h3 className="text-center mb-4 mt-5">Shop Analystics</h3>
              <div className="row row-cols-1 row-cols-md-2 g-4">
                {cardsData.map((card, index) => (
                  <DashboardCard
                    key={index}
                    icon={card.icon}
                    title={card.title}
                    value={card.value}
                  />
                ))}
              </div>
            </div>
          )}
        </main>
      </div>
    </Fragment>
  );
};

export default VendorDashboard;
