import React, { useState, useEffect } from "react";
import { Input, Select, Button } from "antd";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

const { Search } = Input;
const { Option } = Select;

const ProductSearch = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    // Fetch unique categories from the backend
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get("/categories");
        setCategories(data);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearch = () => {
    onSearch(searchTerm, category);
  };

  return (
    <div
      style={{
        marginBottom: "20px",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
      }}
    >
      <Search
        placeholder="Search products by categories"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onSearch={handleSearch}
        style={{
          marginBottom: isMobile ? "10px" : "0",
          marginRight: isMobile ? "0" : "10px",
        }}
      />
      <Select
        placeholder="Select category"
        value={category}
        onChange={(value) => setCategory(value)}
        style={{
          width: isMobile ? "100%" : "200px",
          marginBottom: isMobile ? "10px" : "0",
          marginRight: isMobile ? "0" : "10px",
        }}
      >
        <Option value="">All Categories</Option>
        {categories.map((cat) => (
          <Option key={cat._id} value={cat._id}>
            {cat.categoryName}
          </Option>
        ))}
      </Select>
      <Button type="primary" onClick={handleSearch}>
        Search
      </Button>
    </div>
  );
};

export default ProductSearch;
