import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Badge, Drawer, Button, Dropdown, Menu } from "antd";
import {
  ShoppingCartOutlined,
  MenuUnfoldOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { UserContext } from "../../context";
import { useMediaQuery } from "react-responsive";
import "./layout.css";
import { FaGifts } from "react-icons/fa";

const Nav = () => {
  const [state, setState] = useContext(UserContext);
  const history = useHistory();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [cartCount, setCartCount] = useState(0);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const updateCartCount = () => {
      const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
      setCartCount(cartItems.length);
    };

    window.addEventListener("updateCartCount", updateCartCount);
    updateCartCount(); // Initial count

    return () => {
      window.removeEventListener("updateCartCount", updateCartCount);
    };
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const logout = () => {
    setState({ user: {}, token: "" });
    localStorage.removeItem("auth");
    history.push("/");
  };

  const getAccountLink = () => {
    if (state && state.user && state.user.role) {
      return state.user.role === "Vendor" ? "/vendor/dashboard" : "/dashboard";
    }
    return "/dashboard";
  };

  const getDashboardName = () => {
    if (state && state.user && state.user.role) {
      return state.user.role === "Vendor" ? "Manage Shop" : "My Account";
    }
    return "My Account";
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="account">
        <Link to={getAccountLink()}>{getDashboardName()}</Link>
      </Menu.Item>
      <Menu.Item key="logout">
        <Button type="text" onClick={logout} style={{ width: "100%" }}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  const menuItems = (
    <>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/plans">Plans</Link>
      </li>
      <li>
        <Link to="/gift/shop">Gifts Shop</Link>
      </li>
      <li>
        <Link to="/how-it-works">How it works</Link>
      </li>
      {state && state.token ? (
        <li>
          <Dropdown overlay={userMenu} trigger={["click"]}>
            <Button
              type="link"
              icon={
                <img
                  src={state.user.avatar?.url || "placeholder-avatar.png"}
                  alt="Avatar"
                  style={{ width: 40, height: 40, borderRadius: "50%" }}
                />
              }
            />
          </Dropdown>
        </li>
      ) : (
        <>
          <li>
            <Link to="/login">
              <Button type="link" className="nav-button-color">
                Login
              </Button>
            </Link>
          </li>
          <li>
            <Link to="/vendor/register">
              <Button type="link" className="nav-button-color-vendor">
                List Gifts
              </Button>
            </Link>
          </li>
        </>
      )}
      <li>
        <Link to="/cart">
          <Badge count={cartCount} offset={[9, 0]}>
            <FaGifts className="gift-cart-icon" />
          </Badge>
        </Link>
      </li>
    </>
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-transparent container">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img
            src="/assets/images/runti-logo.png"
            alt="Logo"
            className="logo"
          />
        </Link>
        {isMobile ? (
          <>
            <Button
              type="text"
              icon={isDrawerOpen ? <CloseOutlined /> : <MenuUnfoldOutlined />}
              onClick={toggleDrawer}
              className="navbar-toggler"
            />
            <Drawer
              title="Menu"
              placement="right"
              closable
              onClose={toggleDrawer}
              visible={isDrawerOpen}
            >
              <ul className="drawer-menu mt-4">{menuItems}</ul>
            </Drawer>
          </>
        ) : (
          <ul className="navbar-nav ms-auto horizontal-menu">{menuItems}</ul>
        )}
      </div>
    </nav>
  );
};

export default Nav;
