import React, { useState } from "react";
import "./auth.css";
import axios from "axios";
import { message, Form, Input, Button } from "antd";
import { useParams, Link, useHistory } from "react-router-dom";

const PasswordReset = () => {
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams();
  const history = useHistory();

  const handleSubmit = async (values) => {
    setIsLoading(true);

    try {
      const { data } = await axios.put(`/password/reset/${token}`, {
        password: values.password,
      });

      if (data.error) {
        message.error(data.error);
      } else {
        message.success("Password reset successfully!");
        setTimeout(() => {
          history.push("/login");
        }, 2000);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error || "Error resetting password. Please try again.";
      message.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid bg-body-auth height-100vh">
      <div className="row">
        <div
          className="col-lg-6 p-0"
          style={{
            backgroundImage: "url('/assets/images/runtibgg_new-passreset.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
          }}
        ></div>
        <div className="col-lg-6" style={{ backgroundPosition: "center", height: "100vh" }}>
          <div className="container py-5">
            <div className="d-flex flex-column justify-content-center align-items-center h-100 text-center">
              <h1 className="display-4 fw-bold lh-1 text-body-emphasis mt-3 auth-header-text">
                Reset Password
              </h1>
              <p className="col-lg-10 fs-4">Enter your new password below.</p>
            </div>
            <Form
              className="p-4 p-md-5 border rounded-3 bg-body-auth shadow"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter your new password" },
                  { min: 6, message: "Password must be at least 6 characters" },
                ]}
              >
                <Input.Password
                  placeholder="Enter new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  className="auth-button"
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
