import React, { Fragment, useContext, useEffect, useState } from "react";
import Sidebar from "../../components/layouts/Sidebar";
import { UserContext } from "../../context";
import axios from "axios";
import ErrorAlert from "../../components/alerts/ErrorAlert";
import SuccessAlert from "../../components/alerts/SuccessAlert";
import moment from "moment-timezone"; // Import moment-timezone here
import "moment-timezone";
import "./reminders.css";
import ReminderPage from "./ReminderPage"; 

const StandardReminder = ({ history, match }) => {
  const [state, setState] = useContext(UserContext);
  const [celebrant, setCelebrant] = useState("");
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState("");
  const [datetime, setDatetime] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [type, setType] = useState("");
  const [sendNotification, setSendNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleFileInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      } 
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const lagosDatetime = moment(datetime)
      .tz("Africa/Lagos")
      .format("YYYY-MM-DDTHH:mm:ss");

    try {
      const response = await axios.post("/create/reminder", {
        celebrant,
        image,
        message,
        datetime: lagosDatetime,
        type,
        dateOfBirth,
        sendNotification,
      });

      setSuccess(true);
      setState((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          reminders: [...prev.user.reminders, response.data.reminder],
        },
      }));
      history.push("/dashboard");
      setCelebrant("");
      setImage(null);
      setMessage("");
      setDatetime("");
      setType("");
      setDateOfBirth("");
      setSendNotification(false);
    } catch (error) {
      setError(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  const typeValues = [
    "select celebration",
    "birthday",
    "wedding day",
    "anniversary",
    "graduation",
    "other",
  ];

  return (
    <Fragment>
       <ReminderPage planCode="PLN_l24au1zx0v0uefb">
      <div className="row">
        <Sidebar />
        <main className="col-md-9 mt-7 offset-sm-3">
          <div className="container">
            {error && <ErrorAlert message={error} />}{" "}
            {/* Render ErrorAlert component on error */}
            {success && (
              <SuccessAlert message="Reminder created successfully!" />
            )}{" "}
            {/* Render SuccessAlert component on success */}
            <div className="row g-4 mt-5">
              <div className="col-md-5 col-lg-4 order-md-last">
                <img
                  src={image || "/assets/images/planhero.jpeg"}
                  alt=""
                  className="img-fluid rounded"
                />
              </div>
              <div className="col-md-7 col-lg-5">
                <h1>Standard Reminder Form</h1>
                <form className="p-2 p-md-2" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="celebrant" className="form-label">
                      Celebrant Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="celebrant"
                      name="celebrant"
                      value={celebrant}
                      onChange={(e) => setCelebrant(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="dateOfBirth" className="form-label">
                      Celebrant Speicial Day
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="image" className="form-label">
                      Celebrant Card Image
                    </label>
                    <input
                      type="file"
                      name="image"
                      id="file-input"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleFileInputChange}
                      className="sr-only"
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      Special Message
                    </label>
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="datetime" className="form-label">
                      Set reminder Date time
                    </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="datetime"
                      name="datetime"
                      value={datetime}
                      onChange={(e) => setDatetime(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="type" className="form-label">
                      Category
                    </label>
                    <select
                      className="form-select"
                      id="type"
                      name="type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      required
                    >
                      {typeValues.map((enumValue) => (
                        <option key={enumValue} value={enumValue}>
                          {enumValue}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="sendNotification"
                      name="sendNotification"
                      checked={sendNotification}
                      onChange={(e) => setSendNotification(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="sendNotification"
                    >
                      Send Notification
                    </label>
                  </div>

                  <button type="submit" className="btn reminder-button-plan">
                    {isLoading ? (
                      <div
                        className="spinner-border custom-spin spinner-border-sm me-2"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Create Reminder"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
      </ReminderPage>
    </Fragment>
  );
};

export default StandardReminder;
