import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';


const PaystackCancel = () => {
  const history = useHistory();

  const handleRetry = () => {
    history.push('/');
  };

  return (
    <div>
      <div className="container text-center mt-5 h-100">
        <h1 className="display-4">Payment Cancelled</h1>
        <p className="lead">It looks like your payment was not completed successfully.</p>
        <Button type="primary" size="large" onClick={handleRetry} style={{ marginRight: '10px' }}>
          Retry Payment
        </Button>
        <Button type="default" size="large" onClick={() => history.push('/')}>
         Go back
        </Button>
      </div>
    </div>
  ); 
};

export default PaystackCancel;
