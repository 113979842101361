import React from "react";
import { Card } from "antd";
import PropTypes from "prop-types";

const DashboardCard = ({ icon, title, value }) => {
  return (
    <div className="col-md-6 col-lg-3 mb-4">
      <Card
        className="h-100 shadow-lg"
        Style={{ display: "flex", alignItems: "center" }}
      >
        <div className="me-3">{icon}</div>
        <div>
          <h5 className="mb-1">{title}</h5>
          <p className="mb-0">{value}</p>
        </div>
      </Card>
    </div>
  );
};

DashboardCard.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default DashboardCard;
