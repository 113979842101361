import React from "react";
import Nav from "../../components/layouts/Nav";
import NewsLetter from "../../components/layouts/NewsLetter";
import "./faq.css";
import Footer from "../../components/layouts/Footer";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const HowItWorks = () => {
  return (
    <div>
      <Nav />
      <div className="container mt-5">
        <h3 className="text-center">Frequently Asked questions</h3>
        <div className="justify-content-center">
          <div
            className="accordion mb-5 mt-5"
            id="accordionPanelsStayOpenExample"
          >
            <div className="accordion-item p-2 shadow">
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  How to get started
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body">
                  Our loved ones play a vital role in our lives and it is very
                  important to accord them the love they deserve when they reach
                  certain memorable milestones, from birthdays to anniversaries
                  whatever the celebration Runti helps you stay on top of it.
                  <br />
                  <strong>
                    To get started clicking on the{" "}
                    <NavLink to="/login"> "My Account" </NavLink> Button and
                    create or login to your account.
                  </strong>
                </div>
              </div>
            </div>
            <div className="accordion-item p-2 shadow">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Subscription plans
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse"
              >
                <div className="accordion-body">
                  With our various plans, all you have to do is input the
                  memorable dates you would like for us to remind you about and
                  you leave the rest to us. We'll send a reminder a week to the
                  day and also send you an e-card to send to your loved one.
                  <br />
                  <strong>
                    All our <NavLink to="/plans"> Plans </NavLink> allows our
                    users create reminders and also get a E-card to their loved
                    ones{" "}
                  </strong>
                </div>
              </div>
            </div>
            <div className="accordion-item p-2 shadow">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Reminders
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse"
              >
                <div className="accordion-body">
                 With Runti you will never missed a loved ones celebration again.
                 Runti App provides you with all your reminder updates in the activities page 
                  in your dashboard and also you get email reminders before your loved ones special day
                 <br/>
                 <strong> <NavLink to="/register"> Create an Account </NavLink> to get started Today</strong> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-5 mt-5">
          <div className="col-md-6">
            <div className="text-center">
              <p>Available Soon on iOS</p>
              <img
                src="/assets/images/ios.png"
                alt="iOS Logo"
                style={{ width: "150px", height: "50px" }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-center">
              <p>Available Soon on Android</p>
              <img
                src="/assets/images/andriod.png"
                alt="Android Logo"
                style={{ width: "150px", height: "50px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default HowItWorks;
