import React from "react";
import "./layout.css";

const NewsLetter = () => {
  return (
    <div>
       <div className="container mb-5 mt-7 bg-newsletter shadow-lg rounded-3">
        <div className="py-5 px-5">
          <div className="row">
            <div className="col-md-6 offset-md-3 mb-3">
              <form>
                <h5 className="text-center text-white mb-3">
                  Subscribe to our newsletter
                </h5>
                <p className="text-center text-white">
                  Monthly digest of what's new and exciting from us.
                </p>
                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                  <label htmlFor="newsletter1" className="visually-hidden">
                    Email address
                  </label>
                  <input
                    id="newsletter1"
                    type="text"
                    className="form-control"
                    placeholder="Email address"
                  />
                  <button className="btn newsletter-button" type="button">
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
