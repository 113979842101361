import React, { useState, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context";

const Sidebar = () => {
  const [state, setState] = useContext(UserContext);
  const history = useHistory();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNavLinkClick = () => {
    setIsSidebarOpen(false);
  };

  const logout = () => {
    setState({ user: {}, token: "" });
    localStorage.removeItem("auth");
    history.push("/login");
  };

  return (
    <Fragment>
      <div className="d-lg-flex">
        {/* Toggle button for mobile and tablet */}
        <button
          className="toggle-button d-lg-none"
          onClick={toggleSidebar}
          aria-controls="sidebar"
          aria-expanded={isSidebarOpen}
        >
          {isSidebarOpen ? " " : <i className="bi bi-list"></i>}
        </button>

        {/* Sidebar content */}
        <div
          className={`offcanvas offcanvas-start ${isSidebarOpen ? "show" : ""}`}
          id="sidebar"
        >
          <div className="offcanvas-header">
            <Link to="/" className="navbrand">
              <img
                className=""
                src="/assets/images/runti-logo.png"
                alt="Logo"
                width="160px"
                height="150px"
              />
            </Link>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={toggleSidebar}
              aria-label="Close"
            ></button>
          </div>
          {/* Sidebar links */}
          <div className="offcanvas-body d-flex flex-column">
            <ul className="nav flex-column">
              <li>
                <Link
                  to="/dashboard"
                  className="nav-link"
                  onClick={handleNavLinkClick}
                >
                  <i className="sidebarIcons me-2 fs-5 bi-alarm" />
                  <span className="ms-1 text-dark fw-bold d-sm-inline">
                    Reminders
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/activities"
                  className="nav-link"
                  onClick={handleNavLinkClick}
                >
                  <i className="sidebarIcons me-2 fs-5 bi-clock-history" />
                  <span className="ms-1 text-dark fw-bold d-sm-inline">
                    Activities
                  </span>
                </Link>
              </li>

              <li>
                <Link
                  to="/order/history"
                  className="nav-link"
                  onClick={handleNavLinkClick}
                >
                  <i className="sidebarIcons me-2 fs-5 bi-shop-window" />
                  <span className="ms-1 text-dark fw-bold d-sm-inline">
                    My Orders
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/settings"
                  className="nav-link"
                  onClick={handleNavLinkClick}
                >
                  <i className="sidebarIcons me-2 fs-5 bi-person-gear" />
                  <span className="ms-1 text-dark fw-bold d-sm-inline">
                    Settings
                  </span>
                </Link>
              </li>
              <li>
                <span className="nav-link" onClick={logout}>
                  <i className="sidebarIcons me-2 fs-5 bi-box-arrow-right" />
                  <span className="ms-1 text-dark fw-bold d-sm-inline">
                    Logout
                  </span>
                </span>
              </li>
            </ul>

            {/* Display user avatar */}
            {state &&
              state.token &&
              state.user.avatar &&
              state.user.avatar.url && (
                <div className="user-avatar mt-3">
                  <img
                    src={state.user.avatar.url}
                    alt="User Avatar"
                    className="avatar img-fluid rounded-circle"
                    width="50"
                    height="50"
                  />
                </div>
              )}
          </div>
        </div>

        {/* Fixed sidebar for laptops and desktops */}
        <div
          className={`fixed-sidebar d-none d-lg-block ${
            isSidebarOpen ? "open" : ""
          } flex-column`}
        >
          <Link to="/" className="navbrand">
            <img
              className=""
              src="/assets/images/runti-logo.png"
              alt="Logo"
              width="160px"
              height="150px"
            />
          </Link>
          <ul className="nav flex-column mt-5">
            <li>
              <Link
                to="/dashboard"
                className="nav-link mt-3"
                onClick={handleNavLinkClick}
              >
                <i className="sidebarIcons me-2 fs-5 bi-alarm" />
                <span className="ms-1 text-dark fw-bold d-sm-inline">
                  Reminders
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/activities"
                className="nav-link mt-3"
                onClick={handleNavLinkClick}
              >
                <i className="sidebarIcons me-2 fs-5 bi-clock-history" />
                <span className="ms-1 text-dark fw-bold d-sm-inline">
                  Activities
                </span>
              </Link>
            </li>

            <li>
              <Link
                to="/order/history"
                className="nav-link"
                onClick={handleNavLinkClick}
              >
                <i className="sidebarIcons me-2 fs-5 bi-shop-window" />
                <span className="ms-1 text-dark fw-bold d-sm-inline">
                  My Orders
                </span>
              </Link>
            </li>

            <li>
              <Link
                to="/settings"
                className="nav-link mt-3"
                onClick={handleNavLinkClick}
              >
                <i className="sidebarIcons me-2 fs-5 bi-person-gear" />
                <span className="ms-1 text-dark fw-bold d-sm-inline">
                  Settings
                </span>
              </Link>
            </li>
            <li>
              <span className="nav-link mt-3" onClick={logout}>
                <i className="sidebarIcons me-2 fs-5 bi-box-arrow-right" />
                <span className="ms-1 text-dark fw-bold d-sm-inline">
                  Logout
                </span>
              </span>
            </li>

            {/* Display user avatar */}
            {state &&
              state.token &&
              state.user.avatar &&
              state.user.avatar.url && (
                <div className="user-avatar mt-3 d-flex justify-content-center">
                  <img
                    src={state.user.avatar.url}
                    alt="User Avatar"
                    className="avatar img-fluid rounded-circle"
                    width="50"
                    height="50"
                  />
                </div>
              )}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
