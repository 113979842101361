import React, { Suspense } from "react";
import { Skeleton } from "antd";
import "./hero.css";

const Hero = () => {
  return (
    <div>
      <div className="container col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <Suspense fallback={<Skeleton.Image />}>
              <img
                src="/assets/images/planhero.jpeg"
                className="d-block shadow-lg mx-lg-auto img-fluid plansImage"
                alt=""
                width={600}
                height={300}
                loading="lazy"
              />
            </Suspense>
          </div>
          <div className="col-lg-6">
            <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3 heroText">
              Reminder App <br /> For Everyone 💓
            </h1>
            <p className="lead">
              Never miss any special moments!! Create your reminder and leave
              the rest to us.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
