import React, { useState, useEffect, Fragment, useContext } from "react";
import Sidebar from "../../components/layouts/Sidebar";
import OrderHistory from "../../components/user/OrderHistory.jsx";
import axios from "axios";
import "./user.css";
import { UserContext } from "../../context";

const Orders = () => {
  const [totalOrders, setTotalOrders] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);
  const [deliveredOrders, setDeliveredOrders] = useState(0);
  const [userState] = useContext(UserContext);

  useEffect(() => {
    const fetchOrderStats = async () => {
      try {
        
        // Fetch orders to calculate the statuses
        const ordersResponse = await axios.get("/order/user/order", {
          headers: {
            Authorization: `Bearer ${userState.token}`,
          },
        });

        const orders = ordersResponse.data;
        const total = orders.length;
        const pending = orders.filter(
          (order) => order.status === "Pending"
        ).length;
        const delivered = orders.filter(
          (order) => order.status === "Delivered"
        ).length;

        setTotalOrders(total);
        setPendingOrders(pending);
        setDeliveredOrders(delivered);
      } catch (error) {
        console.error("Error fetching order statistics:", error);
      }
    };

    fetchOrderStats();
  }, [userState.token]);

  return (
    <Fragment>
      <Sidebar />

      <div className="row mt-5">
        <main className="col-lg-8 mx-auto mt-5">
          <div className="container mt-5">
            <div className="row mt-5">
              <div className="col-md-4 mb-4">
                <div className="card h-100 bg-delete text-white shadow">
                  <div className="card-body d-flex flex-column justify-content-center">
                    <i className="bi bi-bag-heart-fill fs-1 mb-3"></i>
                    <h5 className="card-title">Total Orders</h5>
                    <p className="card-text">{totalOrders}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card h-100 bg-pending text-white shadow">
                  <div className="card-body d-flex flex-column justify-content-center">
                    <i className="bi bi-hourglass-split fs-1 mb-3"></i>
                    <h5 className="card-title">Pending Orders</h5>
                    <p className="card-text">{pendingOrders}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card h-100 bg-scheduled text-white shadow">
                  <div className="card-body d-flex flex-column justify-content-center">
                    <i className="bi bi-truck fs-1 mb-3"></i>
                    <h5 className="card-title">Delivered Orders</h5>
                    <p className="card-text">{deliveredOrders}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <OrderHistory />
        </main>
      </div>
    </Fragment>
  );
};

export default Orders;
