import React from "react";
import runtiBdCardImage from "../../images/runti-bd-card.jpeg";
import runtiCardImage2 from "../../images/runti-bd-card2.jpeg";
import runtiCardImage3 from "../../images/runti-bd-card3.jpeg";
import "./cards.css";

const HomeCards = () => {
  return (
    <div>
      <div className="container px-4 py-5 mb-5 mt-7" id="custom-cards">
        <h2 className="pb-2 text-center home-card-text-color">
          Never miss any special moments
        </h2>
        <div className="row row-cols-1 row-cols-md-3 g-4 py-5">
          {[
            {
              image: runtiBdCardImage,
              title: "Birthday Planner",
              location: "Earth",
              duration: "3d",
            },
            {
              image: runtiCardImage2,
              title: "Customized Cards",
              location: "Pakistan",
              duration: "4d",
            },
            {
              image: runtiCardImage3,
              title: "Create Special Moments",
              location: "California",
              duration: "5d",
            },
          ].map((card, index) => (
            <div key={index} className="col">
              <div className="card h-100 card-bg home-card shadow-lg position-relative">
                <img
                  src={card.image}
                  alt="Card background"
                  className="card-img-top"
                  style={{ objectFit: "cover", height: "200px" }}
                />
                <div className="overlay"></div>
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title mb-3 text-white text-center fw-bold">
                    {card.title}
                  </h5>
                  {/* <p className="card-text mb-3 text-white">Location: {card.location}</p>
                  <p className="card-text text-white">Duration: {card.duration}</p> */}
                  {/* <span className="text-center"><i className="bi bi-bell-fill display-6" /></span> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeCards;
