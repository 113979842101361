import React, { useState, useContext } from "react";
import { Form, Input, Button, message } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../context";
import "./auth.css";

const Register = ({ history }) => {
  const [form] = Form.useForm();
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [state, setState] = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleAvatarInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        setAvatarPreview(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleClick = async (values) => {
    try {
      setIsLoading(true);
      const { data } = await axios.post("/register", {
        ...values,
        avatar,
      });

      if (data.error) {
        message.error(data.error);
      } else {
        form.resetFields();
        setAvatar(null);
        setAvatarPreview(null);
        message.success(`Hey ${data.user.name}. You are part of the team now. Congrats!`);
        setState(data);
        localStorage.setItem("auth", JSON.stringify(data));
        setTimeout(() => {
          history.push("/plans");
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong. Try again");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid bg-body-auth">
      <div className="row">
        {/* Image Column */}
        <div
          className="col-lg-6 p-0"
          style={{
            backgroundImage: "url('/assets/images/auth-runti-register.jpeg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
          }}
        ></div>

        {/* Form Column */}
        <div className="col-lg-6 " style={{ backgroundPosition: "center" }}>
          <div className="container py-5">
            <div className="d-flex flex-column justify-content-center align-items-center h-100 text-center">
              <h1 className="display-4 fw-bold lh-1 text-body-emphasis mt-1 auth-header-text">
                Let's Get Started
              </h1>
              <p className="col-lg-10 fs-4">
                Sign up for free. No credit card required.
              </p>
            </div>
            <Form
              form={form}
              onFinish={handleClick}
              className="p-2 p-md-5 border rounded-3 bg-body-transparent shadow"
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please enter your name" }]}
                className="mb-3"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please enter your email" },
                  { type: "email", message: "Please enter a valid email" },
                ]}
                className="mb-3"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[{ required: true, message: "Please enter your phone number" }]}
                className="mb-3"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: "Please enter your password" }]}
                className="mb-3"
              >
                <Input.Password />
              </Form.Item>

              <div className="form-group mb-3">
                <div className="d-flex justify-content-start align-items-center">
                  <label className="form-label fs-7 mt-2">Avatar</label>
                  <span className="mx-2">
                    <input
                      type="file"
                      id="avatar"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleAvatarInputChange}
                      placeholder="Upload Avatar"
                      className="form-control"
                      required
                    />
                  </span>
                  {avatarPreview && (
                    <span className="badge bg-transparent rounded-pill">
                      <img
                        className="rounded-circle me-1"
                        width="34"
                        height="34"
                        src={avatarPreview}
                        alt="Avatar Preview"
                      />
                    </span>
                  )}
                  {!avatarPreview && (
                    <span className="badge bg-transparent rounded-pill">
                      <i className="bi bi-person-circle auth-icon-avatar display-6"></i>
                    </span>
                  )}
                </div>
              </div>

              <div className="d-grid auth-button-container mt-3 mb-3">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  className="auth-button"
                >
                  Register
                </Button>
              </div>

              <div className="checkbox mb-3 text-center">
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Input type="checkbox" /> Remember me
                </Form.Item>
              </div>

              <hr className="my-4" />

              <p className="text-body-secondary text-center">
                Already have an account? <Link to="/login">Login</Link> to your
                account.
              </p>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
