import React, { useState, useEffect } from "react";
import { Modal, Form, Input, DatePicker, Select, message } from "antd";
import axios from "axios";

const { Option } = Select;

const AddDiscountModal = ({ visible, onCancel, onSave }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (visible) {
      const fetchProducts = async () => {
        try {
          const { data } = await axios.get("/products/vendor");
          setProducts(data);
        } catch (error) {
          message.error("Failed to fetch products");
        }
      };
      fetchProducts();
    }
  }, [visible]);

  const handleCreateDiscount = async (values) => {
    setLoading(true);
    try {
      const { data } = await axios.post("/discount/create", {
        discountCode: values.discountCode,
        discountPercentage: values.discountPercentage,
        expiryDate: values.expiryDate.format("YYYY-MM-DD"), // Format date if needed
        product: values.productId, // Use the correct field name
      });
      onSave(data);
      message.success("Discount created successfully!");
      form.resetFields();
      onCancel();
    } catch (error) {
      message.error("Failed to create discount");
      console.error(error); // Log error for debugging
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={() => form.submit()}
      confirmLoading={loading}
      title="Create Discount"
    >
      <Form form={form} onFinish={handleCreateDiscount}>
        <Form.Item
          name="discountCode"
          label="Discount Code"
          rules={[{ required: true, message: "Please enter a discount code" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="discountPercentage"
          label="Discount Percentage"
          rules={[{ required: true, message: "Please enter a percentage" }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="expiryDate"
          label="Expiry Date"
          rules={[{ required: true, message: "Please select an expiry date" }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
  name="productId" // Ensure this matches the field in your backend
  label="Select Product"
  rules={[{ required: true, message: "Please select a product" }]}
>
  <Select placeholder="Select a product">
    {products.map((product) => (
      <Option key={product._id} value={product._id}>
        {product.productName}
      </Option>
    ))}
  </Select>
</Form.Item>

      </Form>
    </Modal>
  );
};

export default AddDiscountModal;
