import React, { useEffect, useState, useContext, Fragment } from "react";
import VendorSidebar from "../../components/layouts/VendorSidebar.jsx";
import axios from "axios";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../components/layouts/Footer";
import { UserContext } from "../../context";
import {
  AppstoreOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  TruckOutlined,
} from "@ant-design/icons";
import DashboardCard from "../../components/vendor/dashboardcard/DashboardCard.jsx";
import { Table, Button, message, Modal } from "antd";

const ManageOrders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [state] = useContext(UserContext);
  const history = useHistory();

  const cardsData = [
    {
      icon: <AppstoreOutlined style={{ fontSize: "24px", color: "#1890ff" }} />,
      title: "Total Orders",
      value: orders.length,
    },
    {
      icon: <SyncOutlined style={{ fontSize: "24px", color: "#1890ff" }} />,
      title: "Pending Orders",
      value: orders.filter(order => order.status === "Pending").length,
    },
    {
      icon: <TruckOutlined style={{ fontSize: "24px", color: "#1890ff" }} />,
      title: "Delivered Orders",
      value: orders.filter(order => order.status === "Delivered").length,
    },
    {
      icon: <CloseCircleOutlined style={{ fontSize: "24px", color: "#1890ff" }} />,
      title: "Canceled Orders",
      value: orders.filter(order => order.status === "Canceled").length,
    },
  ];

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get("/order/vendor/orders", {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setOrders(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        message.error("Failed to load orders.");
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [state.token]);

  const columns = [
    {
      title: "Order ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => `₦${(text + record.deliveryFee).toFixed(2)}`,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => handleViewOrder(record)}
        >
          View
        </Button>
      ),
    },
  ];

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedOrder(null);
  };

  return (
    <Fragment>
      <VendorSidebar />

      <div className="row">
        <main className={`col-lg-8 mx-auto mt-5 container`}>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
              <div className="spinner-border custom-spin" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="container mt-5">
              <h3 className="text-center mb-4 mt-5">Manage Orders</h3>
              <div className="row row-cols-1 row-cols-md-2 g-4">
                {cardsData.map((card, index) => (
                  <DashboardCard
                    key={index}
                    icon={card.icon}
                    title={card.title}
                    value={card.value}
                  />
                ))}
              </div>
              <Table
                columns={columns}
                dataSource={orders}
                rowKey="_id"
                pagination={{ pageSize: 5 }}
              />
            </div>
          )}
        </main>
      </div>

      <Modal
        title="Order Details"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {selectedOrder && (
          <div>
            <p><strong>Order ID:</strong> {selectedOrder._id}</p>
            <p><strong>Date:</strong> {moment(selectedOrder.createdAt).format("YYYY-MM-DD")}</p>
            <p><strong>Status:</strong> {selectedOrder.status}</p>
            <p><strong>Delivery Address:</strong> {selectedOrder.address}, {selectedOrder.state}</p>
            <p><strong>Total Amount:</strong> ₦{(selectedOrder.amount + selectedOrder.deliveryFee).toFixed(2)}</p>
            
            <h4>Products:</h4>
            {selectedOrder.products.map((item) => (
              <div key={item._id} style={{ marginBottom: "10px", borderBottom: "1px solid #f0f0f0", paddingBottom: "10px" }}>
                <p><strong>Product Name:</strong> {item.product.productName}</p>
                <p><strong>Quantity:</strong> {item.quantity}</p>
                <p><strong>Unit Price:</strong> ₦{item.product.price}</p>
                <p><strong>Total Price:</strong> ₦{item.price}</p>
                {/* Display product image if available */}
                {item.product.image && (
                  <img
                    src={item.product.image}
                    alt={item.product.productName}
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                  />
                )}
                {/* Display other product properties if available */}
                {item.product.color && (
                  <p><strong>Color:</strong> {item.product.color}</p>
                )}
                {item.product.size && (
                  <p><strong>Size:</strong> {item.product.size}</p>
                )}
                {/* Add more product properties as needed */}
              </div>
            ))}
          </div>
        )}
      </Modal>

      <Footer />
    </Fragment>
  );
};

export default ManageOrders;
