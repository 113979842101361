import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Upload, 
  Select,
  message,
  Col,
  Row,
} from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";

const { Option } = Select;

const AddProductModal = ({ visible, onCancel, onSave }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      const fetchVendorCategories = async () => {
        try {
          const { data } = await axios.get("/categories/vendor");
          setCategories(data);
        } catch (error) {
          message.error("Failed to fetch vendor categories");
        }
      };

      fetchVendorCategories();
    }
  }, [visible]);

  const handleFileChange = (info) => {
    if (info.fileList) {
      setFileList(info.fileList); // Update file list
    }
  };

  const handleCreateProduct = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const formData = new FormData();

      if (fileList.length > 0) {
        formData.append("productImage", fileList[0].originFileObj);
      }

      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      const response = await axios.post("/product/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      onSave(values);
      setFileList([]);
      message.success("Product created successfully!");
      form.resetFields();
      onCancel();
    } catch (error) {
      message.error("Failed to create product");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Add Product"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleCreateProduct}
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="addProductForm">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="productName"
              label="Product Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="price" label="Price" rules={[{ required: true }]}>
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              name="categories"
              label="Categories"
              rules={[{ required: true }]}
            >
              <Select mode="multiple" placeholder="Select categories">
                {categories.map((category) => (
                  <Option key={category._id} value={category._id}>
                    {category.categoryName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[{ required: true }]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item name="color" label="Color">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="size" label="Size">
              <Input />
            </Form.Item>
            <Form.Item name="shipping" label="Shipping">
              <Select placeholder="Select shipping option">
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="productImage"
              label="Product Image"
              rules={[{ required: true }]}
            >
              <Upload
                listType="picture-card"
                fileList={fileList}
                showUploadList={false}
                beforeUpload={() => false}
                onChange={handleFileChange}
              >
                {fileList.length > 0 ? (
                  <img
                    src={URL.createObjectURL(fileList[0].originFileObj)}
                    alt="product"
                    style={{ width: "100%" }}
                  />
                ) : (
                  <div>
                    <UploadOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddProductModal;
